import {Providers} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getProviders(context, pagination) {
        return Providers.getProviders(pagination).then((response) => {
            return response.data;
        });
    },
    getProvider(context, id) {
        return Providers.get({id}).then((response) => {
            return response.data;
        });
    },
    createProvider(context, item) {
        return Providers.create(item).then((response) => {
            return response.data;
        });
    },
    updateProvider(context, item) {
        return Providers.update(item).then((response) => {
            return response.data;
        });
    },
    createProviderPublic(context, item) {
        return Providers.createPublic(item).then((response) => {
            return response.data;
        });
    },
    updateProviderPublic(context, item) {
        return Providers.updatePublic(item).then((response) => {
            return response.data;
        });
    },
    destroyProvider(context, item) {
        return Providers.destroy(item).then((response) => {
            return response.data;
        });
    },
    getProviderField(context, item) {
        return Providers.getProviderField(item).then((response) => {
            return response.data;
        });
    },
    sendProvidersEmail(context, item) {
        return Providers.sendProvidersEmail(item).then((response) => {
            return response.data;
        });
    },
    approveProviders(context, item) {
        return Providers.approveProviders(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;