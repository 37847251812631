import {RequestItemSuggestions} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
  getRequestItemSuggestions(context, pagination) {
    return RequestItemSuggestions.getRequestItemSuggestions(pagination).then((response) => {
      return response.data;
    });
  },
  getRequestItemSuggestion(context, id) {
    return RequestItemSuggestions.get({id}).then((response) => {
      return response.data;
    });
  },
  createRequestItemSuggestion(context, item) {
    return RequestItemSuggestions.create(item).then((response) => {
      return response.data;
    });
  },
  updateRequestItemSuggestion(context, item) {
    return RequestItemSuggestions.update(item).then((response) => {
      return response.data;
    });
  },
  destroyRequestItemSuggestion(context, item) {
    return RequestItemSuggestions.destroy(item).then((response) => {
      return response.data;
    });
  },
  getRequestItemSuggestionField(context, item) {
    return RequestItemSuggestions.getRequestItemSuggestionField(item).then((response) => {
      return response.data;
    });
  },
  sendRequestItemSuggestionsEmail(context, item) {
    return RequestItemSuggestions.sendRequestItemSuggestionsEmail(item).then((response) => {
      return response.data;
    });
  },
};

const module = {
  state,
  mutations,
  actions
};

export default module;
