import {AccessProfiles} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getAccessProfiles(context, pagination) {
        return AccessProfiles.getAccessProfiles(pagination).then((response) => {
            return response.data;
        });
    },
    getAccessProfile(context, id) {
        return AccessProfiles.get({id}).then((response) => {
            return response.data;
        });
    },
    createAccessProfile(context, item) {
        return AccessProfiles.create(item).then((response) => {
            return response.data;
        });
    },
    updateAccessProfile(context, item) {
        return AccessProfiles.update(item).then((response) => {
            return response.data;
        });
    },
    destroyAccessProfile(context, item) {
        return AccessProfiles.destroy(item).then((response) => {
            return response.data;
        });
    },
    getRolesModules(context, item) {
        return AccessProfiles.getRolesModules(item).then((response) => {
            return response.data;
        });
    },
    syncPermissions(context, item) {
        return AccessProfiles.syncPermissions(item).then((response) => {
            return response.data;
        });
    },
    getRequestAccessProfile(context, item) {
        return AccessProfiles.getRequestAccessProfile(item).then((response) => {
            return response.data;
        });
    },
    createRequestAccessProfile(context, item) {
        return AccessProfiles.createRequestAccessProfile(item).then((response) => {
            return response.data;
        });
    },
    destroyRequestAccessProfile(context, item) {
        return AccessProfiles.destroyRequestAccessProfile(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
