import {GroupItemItems} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getGroupItemItems(context, pagination) {
        return GroupItemItems.getGroupItemItems(pagination).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};
export default module;
