import Vue from 'vue'

Vue.filter('filterDate', function (element) {
    if (element) {
        element = element.split(' ');
        if (element) {
            return element[0].split('-').reverse().join("/");
        }
    }
});

Vue.filter('filterValue', function (value) {
    value = parseFloat(value);

    let response = value
        .toLocaleString('pt-br', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

    return (response === 'R$ NaN' || response == 'NaN') ? 'R$ 0,00' : response;
});

Vue.filter('filterPhone', function (element) {
    if (element) {
        return element
            .replace(/[^0-9]/g, '')
            .replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    }
});

Vue.filter("filterCNPJ", (element) => {
    if (element) {
        return element
            .replace(/\D/g, '')
            .replace(/^(\d{2})(\d)/, '$1.$2')
            .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
            .replace(/\.(\d{3})(\d)/, '.$1/$2')
            .replace(/(\d{4})(\d)/, '$1-$2');
    }
});

Vue.filter("filterCPF", (element) => {
    if (element) {
        return element
            .replace(/\D/g, '')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    }
});