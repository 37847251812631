import {ElectronicAuctions} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getElectronicAuctions(context, pagination) {
        return ElectronicAuctions.getElectronicAuctions(pagination).then((response) => {
            return response.data;
        });
    },
    getElectronicAuction(context, id) {
        return ElectronicAuctions.get({id}).then((response) => {
            return response.data;
        });
    },
    createElectronicAuction(context, item) {
        return ElectronicAuctions.create(item).then((response) => {
            return response.data;
        });
    },
    updateElectronicAuction(context, item) {
        return ElectronicAuctions.update(item).then((response) => {
            return response.data;
        });
    },
    destroyElectronicAuction(context, item) {
        return ElectronicAuctions.destroy(item).then((response) => {
            return response.data;
        });
    },
    getElectronicAuctionField(context, item) {
        return ElectronicAuctions.getElectronicAuctionField(item).then((response) => {
            return response.data;
        });
    },
    sendElectronicAuctionsEmail(context, item) {
        return ElectronicAuctions.sendElectronicAuctionsEmail(item).then((response) => {
            return response.data;
        });
    },
    checkIdentifierElectronicAuctions(context, item) {
        return ElectronicAuctions.checkIdentifierElectronicAuctions(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
