import {PriceRecords} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getPriceRecords(context, pagination) {
        return PriceRecords.getPriceRecords(pagination).then((response) => {
            return response.data;
        });
    },
    getPriceRecord(context, id) {
        return PriceRecords.get({id}).then((response) => {
            return response.data;
        });
    },
    createPriceRecord(context, item) {
        return PriceRecords.create(item).then((response) => {
            return response.data;
        });
    },
    updatePriceRecord(context, item) {
        return PriceRecords.update(item).then((response) => {
            return response.data;
        });
    },
    destroyPriceRecord(context, item) {
        return PriceRecords.destroy(item).then((response) => {
            return response.data;
        });
    },
    getPriceRecordField(context, item) {
        return PriceRecords.getPriceRecordField(item).then((response) => {
            return response.data;
        });
    },
    sendPriceRecordsEmail(context, item) {
        return PriceRecords.sendPriceRecordsEmail(item).then((response) => {
            return response.data;
        });
    },
    checkIdentifierPriceRecords(context, item) {
        return PriceRecords.checkIdentifierPriceRecords(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
