import {PriceConsultationProviders} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getPriceConsultationProviders(context, pagination) {
        return PriceConsultationProviders.getPriceConsultationProviders(pagination).then((response) => {
            return response.data;
        });
    },
    sendPriceConsultationProvidersEmail(context, item) {
        return PriceConsultationProviders.sendPriceConsultationProvidersEmail(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
