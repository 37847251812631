import Vue from 'vue'
import App from './App'
import Vuetify from 'vuetify'
import Keycloak from 'keycloak-js'
import VeeValidate, {Validator} from 'vee-validate'
import pt_BR from 'vee-validate/dist/locale/pt_BR'
import store from './store/store'
import router from './router'
import colors from 'vuetify/es5/util/colors'
import './filter'
import 'vuetify/dist/vuetify.min.css'
import LocalStorageService from "@/services/localStorage";
import VuetifyMoney from "vuetify-money";
import 'material-design-icons-iconfont/dist/material-design-icons.css';

Vue.use(Vuetify, {theme: {primary: colors.red.darken1, secondary: colors.red.darken1, accent: colors.red.base}});
Vue.use(VeeValidate);
Vue.use(VuetifyMoney);

Validator.localize('pt_BR', pt_BR);

Vue.http.options.root = process.env.VUE_APP_URL_API;
Vue.config.productionTip = false;

/**
 * keycloak
 */
let showLog = false;
let initOptions = {
    url: process.env.VUE_APP_KEYCLOAK_URL,
    realm: process.env.VUE_APP_KEYCLOAK_REALM,
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT,
    onLoad: 'check-sso',
}
let keycloak = Keycloak(initOptions);
Vue.prototype.$keycloak = keycloak;

keycloak.init({
    onLoad: initOptions.onLoad,
    checkLoginIframe: true,
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    enableLogging: true
}).then((auth) => {
    if (auth) {
        // Salva o token JWT no LocalStorage
        LocalStorageService.set('token', keycloak.token);

        // Dispara as ações de autenticação e de checagem de menu
        store.commit('authenticated');
        store.commit('menuCheck');

        // Obtém informações do usuário atual do banco de dados
        store.dispatch('getUser')
            .then((response) => {
                response = response.data;

                // Redireciona para a página de validação de usuário externo, se necessário
                if (response.external_user && response.redirect_validacao) {
                    router.push({path: `external-users/${response.external_user.id}/edit`});
                }

                return response;
            })
            .catch((response) => {
                if (response && response.data) {
                    if (response.data.message.match(/User not found/)) {
                        keycloak.logout();
                    }
                }
            })

        // Redireciona para a página de dashboard se o usuário estiver na página de login
        if (router.history.current.name === 'login') {
            router.push({name: 'dashboard'});
        }

        // Atualiza o token JWT a cada 70 segundos
        setInterval(() => {
            keycloak.updateToken(70).then((refreshed) => {
                if (refreshed) {
                    // Salva o novo token JWT no LocalStorage
                    LocalStorageService.set('token', keycloak.token);

                    if (showLog) console.log('Token atualizado');
                } else {
                    if (showLog) console.log('Não foi possível atualizar o token, válido por ' + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' segundos');
                }
            }).catch(() => {
                if (showLog) console.log('Falha ao atualizar o token');
            });
        }, 70000);
    }

    router.beforeEach((to, from, next) => {
        to.matched.some(route => {
            let authenticated = store.state.auth.check;
            let user = store.state.auth.user;

            if (authenticated) {
                let defaultPage = 'dashboard';

                if (defaultPage !== route.name) {
                    if (route.meta.auth) {
                        let type = parseInt(user.tipoUsuario);

                        if (!route.meta.types.includes(type)) {
                            return router.push({name: 'dashboard'});
                        }

                        if (type === 1) {
                            const roles = route.meta.roles;

                            if (roles) {
                                const status = roles.some(role => keycloak.hasRealmRole(role));

                                if (!status) {
                                    router.push({name: defaultPage});
                                }
                            }
                        }
                    } else {
                        return router.push({name: defaultPage});
                    }
                }
            } else {
                if (route.meta.auth) {
                    return router.push({name: 'login'});
                }
            }

            next()
        });
    });

    // Inicializa a instância do Vue após a autenticação do Keycloak
    new Vue({
        el: '#app',
        router,
        components: {App},
        template: '<App/>',
    });
}).catch((error) => {
    if (showLog) console.error("Falha na inicialização do Keycloak.", error);
});
