import {PriceConsultations} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getPriceConsultations(context, pagination) {
        return PriceConsultations.getPriceConsultations(pagination).then((response) => {
            return response.data;
        });
    },
    getPriceConsultation(context, id) {
        return PriceConsultations.get({id}).then((response) => {
            return response.data;
        });
    },
    createPriceConsultation(context, item) {
        return PriceConsultations.create(item).then((response) => {
            return response.data;
        });
    },
    updatePriceConsultation(context, item) {
        return PriceConsultations.update(item).then((response) => {
            return response.data;
        });
    },
    destroyPriceConsultation(context, item) {
        return PriceConsultations.destroy(item).then((response) => {
            return response.data;
        });
    },
    getPriceConsultationField(context, item) {
        return PriceConsultations.getPriceConsultationField(item).then((response) => {
            return response.data;
        });
    },
    getPriceConsultationItemsField(context, item) {
        return PriceConsultations.getPriceConsultationItemsField(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
