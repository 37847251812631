import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/default', name: 'default', component: () => import('@/components/DefaultPage'),
            meta: {auth: true},
        },
        {
            path: '/', name: 'login', component: () => import('@/components/auth/LoginPage'),
            meta: {auth: false},
        },
        {
            path: '/dashboard', name: 'dashboard', component: () => import('@/components/DashboardPage'),
            meta: {auth: true},
        },
        {
            path: '/register', name: 'register', component: () => import('@/components/auth/RegisterPage'),
            meta: {auth: false},
        },
        {
            path: '/register-provider',
            name: 'register-provider',
            component: () => import('@/components/auth/RegisterProviderPage'),
            meta: {auth: false},
        },
        {
            path: '/register-external-user',
            name: 'register-external-user',
            component: () => import('@/components/auth/RegisterExternalUserPage'),
            meta: {auth: false},
        },
        {
            path: '/reset', name: 'reset', component: () => import('@/components/auth/ResetPage'),
            meta: {auth: false},
        },
        {
            path: '/reset/password/:token',
            name: 'reset.password',
            component: () => import('@/components/auth/ResetPasswordPage'),
            meta: {auth: false},
        },
        {
            path: '/profile', name: 'profile', component: () => import('@/components/user/ProfilePage'),
            meta: {auth: true},
        },
        {
            path: '/users', name: 'users', component: () => import('@/components/users/UsersPage'),
            meta: {auth: true},
        },

        /**
         * products
         */
        {
            path: '/products', name: 'products.index',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_ITEM_VIEW'
                ]
            },
            component: () => import('@/components/products/ProductPage'),
        },
        {
            path: '/products/create', name: 'products.create',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_ITEM_CREATE'
                ]
            },
            component: () => import('@/components/products/forms/ProductForm'),
        },
        {
            path: '/products/:id/edit',
            name: 'products.edit',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_ITEM_VIEW'
                ]
            },
            component: () => import('@/components/products/forms/ProductForm'),
        },

        /**
         * attributes-items
         */
        {
            path: '/attributes-items', name: 'attributes-items.index',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_ATTRIBUTE_ITEM_VIEW'
                ]
            },
            component: () => import('@/components/attributes-items/AttributePage'),
        },
        {
            path: '/attributes-items/create',
            name: 'attributes-items.create',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_ATTRIBUTE_ITEM_CREATE'
                ]
            },
            component: () => import('@/components/attributes-items/forms/AttributeForm'),
        },
        {
            path: '/attributes-items/:id/edit',
            name: 'attributes-items.edit',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_ATTRIBUTE_ITEM_VIEW'
                ]
            },
            component: () => import('@/components/attributes-items/forms/AttributeForm'),
        },

        /**
         * elements-items
         */
        {
            path: '/elements-items', name: 'elements-items.index',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_ELEMENT_ITEM_VIEW'
                ]
            },
            component: () => import('@/components/elements-items/ElementPage'),
        },
        {
            path: '/elements-items/create', name: 'elements-items.create',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_ELEMENT_ITEM_CREATE'
                ]
            },
            component: () => import('@/components/elements-items/forms/ElementForm'),
        },
        {
            path: '/elements-items/:id/edit',
            name: 'elements-items.edit',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_ELEMENT_ITEM_VIEW'
                ]
            },
            component: () => import('@/components/elements-items/forms/ElementForm'),
        },

        /**
         * tags
         */
        {
            path: '/tags', name: 'tags.index',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_TAG_VIEW'
                ]
            },
            component: () => import('@/components/tags/TagPage'),
        },
        {
            path: '/tags/create', name: 'tags.create',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_TAG_CREATE'
                ]
            },
            component: () => import('@/components/tags/forms/TagForm'),
        },
        {
            path: '/tags/:id/edit',
            name: 'tags.edit',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_TAG_VIEW'
                ]
            },
            component: () => import('@/components/tags/forms/TagForm'),
        },

        /**
         * categories
         */
        {
            path: '/categories', name: 'categories.index',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_CATEGORY_VIEW'
                ]
            },
            component: () => import('@/components/categories/CategoryPage'),
        },
        {
            path: '/categories/create', name: 'categories.create',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_CATEGORY_CREATE'
                ]
            },
            component: () => import('@/components/categories/forms/CategoryForm'),
        },
        {
            path: '/categories/:id/edit',
            name: 'categories.edit',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_CATEGORY_VIEW'
                ]
            },
            component: () => import('@/components/categories/forms/CategoryForm'),
        },

        /**
         * subcategories
         */
        {
            path: '/subcategories', name: 'subcategories.index',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_SUBCATEGORY_VIEW'
                ]
            },
            component: () => import('@/components/subcategories/SubcategoryPage'),
        },
        {
            path: '/subcategories/create',
            name: 'subcategories.create',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_SUBCATEGORY_CREATE'
                ]
            },
            component: () => import('@/components/subcategories/forms/SubcategoryForm'),
        },
        {
            path: '/subcategories/:id/edit',
            name: 'subcategories.edit',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_SUBCATEGORY_VIEW'
                ]
            },
            component: () => import('@/components/subcategories/forms/SubcategoryForm'),
        },

        /**
         * acquisition-units
         */
        {
            path: '/acquisition-units',
            name: 'acquisition-units.index',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_ACQUISITION_UNIT_VIEW'
                ]
            },
            component: () => import('@/components/acquisition-units/AcquisitionUnitsPage'),
        },
        {
            path: '/acquisition-units/create',
            name: 'acquisition-units.create',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_ACQUISITION_UNIT_CREATE'
                ]
            },
            component: () => import('@/components/acquisition-units/forms/AcquisitionUnitsForm'),
        },
        {
            path: '/acquisition-units/:id/edit',
            name: 'acquisition-units.edit',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_ACQUISITION_UNIT_VIEW'
                ]
            },
            component: () => import('@/components/acquisition-units/forms/AcquisitionUnitsForm'),
        },

        /**
         * users-purchase-management
         */
        {
            path: '/users-purchase-management',
            name: 'users-purchase-management.index',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_USERS_PURCHASE_MANAGEMENT_VIEW'
                ]
            },
            component: () => import('@/components/users-purchase-management/UsersPurchaseManagementPage'),
        },
        {
            path: '/users-purchase-management/create',
            name: 'users-purchase-management.create',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_USERS_PURCHASE_MANAGEMENT_CREATE'
                ]
            },
            component: () => import('@/components/users-purchase-management/forms/UsersPurchaseManagementForm'),
        },
        {
            path: '/users-purchase-management/:id/edit',
            name: 'users-purchase-management.edit',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_USERS_PURCHASE_MANAGEMENT_VIEW'
                ]
            },
            component: () => import('@/components/users-purchase-management/forms/UsersPurchaseManagementForm'),
        },

        /**
         * purchase-managements
         */
        {
            path: '/purchase-managements',
            name: 'purchase-managements.index',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_PURCHASE_MANAGEMENT_VIEW'
                ]
            },
            component: () => import('@/components/purchase-managements/PurchaseManagementPage'),
        },
        {
            path: '/purchase-managements/create',
            name: 'purchase-managements.create',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_PURCHASE_MANAGEMENT_CREATE'
                ]
            },
            component: () => import('@/components/purchase-managements/forms/PurchaseManagementForm'),
        },
        {
            path: '/purchase-managements/:id/edit',
            name: 'purchase-managements.edit',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_PURCHASE_MANAGEMENT_VIEW'
                ]
            },
            component: () => import('@/components/purchase-managements/forms/PurchaseManagementForm'),
        },

        /**
         * config
         */
        {
            path: '/config', name: 'config.index',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_CONFIG_VIEW'
                ]
            },
            component: () => import('@/components/config/forms/ConfigForm'),
        },

        /**
         * providers
         */
        {
            path: '/providers', name: 'providers.index',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_PROVIDER_VIEW'
                ]
            },
            component: () => import('@/components/providers/ProviderPage'),
        },
        {
            path: '/providers/create', name: 'providers.create',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_PROVIDER_CREATE'
                ]
            },
            component: () => import('@/components/providers/forms/ProviderForm'),
        },
        {
            path: '/providers/:id/edit',
            name: 'providers.edit',
            meta: {
                auth: true,
                types: [1, 2],
                roles: [
                    'OS10_PROVIDER_VIEW'
                ]
            },
            component: () => import('@/components/providers/forms/ProviderForm'),
        },

        /**
         * external users
         */
        {
            path: '/external-users', name: 'external-users.index',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_EXTERNAL_USER_VIEW'
                ]
            },
            component: () => import('@/components/external-users/ExternalUserPage'),
        },
        {
            path: '/external-users/create',
            name: 'external-users.create',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_EXTERNAL_USER_CREATE'
                ]
            },
            component: () => import('@/components/external-users/forms/ExternalUserForm'),
        },
        {
            path: '/external-users/:id/edit',
            name: 'external-users.edit',
            meta: {
                auth: true,
                types: [1, 3],
                roles: [
                    'OS10_EXTERNAL_USER_VIEW'
                ]
            },
            component: () => import('@/components/external-users/forms/ExternalUserForm'),
        },

        /**
         * supply-products
         */
        {
            path: '/supply-products', name: 'supply-products.index',
            meta: {
                auth: true,
                types: [2, 3],
            },
            component: () => import('@/components/supply-products/SupplyProductPage'),
        },
        {
            path: '/supply-products/create',
            name: 'supply-products.create',
            meta: {
                auth: true,
                types: [2, 3],
            },
            component: () => import('@/components/supply-products/forms/SupplyProductForm'),
        },
        {
            path: '/supply-products/:id/edit',
            name: 'supply-products.edit',
            meta: {
                auth: true,
                types: [2, 3],
            },
            component: () => import('@/components/supply-products/forms/SupplyProductForm'),
        },
        {
            path: '/supplied-products/providers', name: 'supplied-products.providers.index',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_SUPPLIED_PRODUCTS_PROVIDER_VIEW'
                ]
            },
            component: () => import('@/components/supply-products/SuppliedProductProviderPage'),
        },
        {
            path: '/supplied-products/external-users', name: 'supplied-products.external-users.index',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_SUPPLIED_PRODUCTS_EXTERNAL_USER_VIEW'
                ]
            },
            component: () => import('@/components/supply-products/SuppliedProductExternalUserPage'),
        },

        /**
         * access-profile
         */
        {
            path: '/access-profile',
            name: 'access-profile.index',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_ACCESS_PROFILE_VIEW'
                ]
            },
            component: () => import('@/components/access-profile/AccessProfilePage'),
        },
        {
            path: '/access-profile/create',
            name: 'access-profile.create',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_ACCESS_PROFILE_CREATE'
                ]
            },
            component: () => import('@/components/access-profile/forms/AccessProfileForm'),
        },
        {
            path: '/access-profile/:id/edit',
            name: 'access-profile.edit',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_ACCESS_PROFILE_VIEW'
                ]
            },
            component: () => import('@/components/access-profile/forms/AccessProfileForm'),
        },
        {
            path: '/access-profile/request',
            name: 'access-profile.request',
            meta: {
                auth: true,
                types: [1],
            },
            component: () => import('@/components/access-profile/forms/RequestAccessProfileForm'),
        },

        /**
         * users-access-profile
         */
        {
            path: '/users-access-profile',
            name: 'users-access-profile.index',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_USERS_ACCESS_PROFILE_VIEW'
                ]
            },
            component: () => import('@/components/users-access-profile/UsersAccessProfilePage'),
        },
        {
            path: '/users-access-profile/:id/edit',
            name: 'users-access-profile.edit',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_USERS_ACCESS_PROFILE_VIEW'
                ]
            },
            component: () => import('@/components/users-access-profile/forms/UserAccessProfileForm'),
        },

        /**
         * request-item-suggestions
         */
        {
            path: '/request-item-suggestions',
            name: 'request-item-suggestions.index',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_REQUEST_ITEM_SUGGESTION_VIEW'
                ]
            },
            component: () => import('@/components/request-item-suggestions/RequestItemSuggestionPage'),
        },
        {
            path: '/request-item-suggestions/create',
            name: 'request-item-suggestions.create',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_REQUEST_ITEM_SUGGESTION_CREATE'
                ]
            },
            component: () => import('@/components/request-item-suggestions/forms/RequestItemSuggestionForm'),
        },
        {
            path: '/request-item-suggestions/:id/edit',
            name: 'request-item-suggestions.edit',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_REQUEST_ITEM_SUGGESTION_VIEW'
                ]
            },
            component: () => import('@/components/request-item-suggestions/forms/RequestItemSuggestionForm'),
        },
        {
            path: '/item-suggestions-request-email/providers',
            name: 'item-suggestions-request-email.providers',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_SEND_REQUEST_ITEM_SUGGESTION_PROVIDER_VIEW'
                ]
            },
            component: () => import('@/components/request-item-suggestions/ItemSuggestionRequestEmailOfProviderPage'),
        },
        {
            path: '/item-suggestions-request-email/external-users',
            name: 'item-suggestions-request-email.external-users',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_SEND_REQUEST_ITEM_SUGGESTION_EXTERNAL_USER_VIEW'
                ]
            },
            component: () => import('@/components/request-item-suggestions/ItemSuggestionRequestEmailOfExternalUserPage'),
        },

        /**
         * suggested-items
         */
        {
            path: '/suggested-items',
            name: 'suggested-items.index',
            meta: {
                auth: true,
                types: [2, 3],
            },
            component: () => import('@/components/suggested-items/SuggestedItemPage'),
        },
        {
            path: '/suggested-items/create',
            name: 'suggested-items.create',
            meta: {
                auth: true,
                types: [2, 3],
            },
            component: () => import('@/components/suggested-items/forms/SuggestedItemForm'),
        },
        {
            path: '/suggested-items/:id/edit',
            name: 'suggested-items.edit',
            meta: {
                auth: true,
                types: [2, 3],
            },
            component: () => import('@/components/suggested-items/forms/SuggestedItemForm'),
        },
        {
            path: '/suggested-items/providers',
            name: 'suggested-items.providers',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_SUGGESTED_ITEM_PROVIDER_VIEW'
                ]
            },
            component: () => import('@/components/suggested-items/SuggestedItemOfProviderPage'),
        },
        {
            path: '/suggested-items/external-users',
            name: 'suggested-items.external-users',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_SUGGESTED_ITEM_EXTERNAL_USER_VIEW'
                ]
            },
            component: () => import('@/components/suggested-items/SuggestedItemOfExternalUserPage'),
        },

        /**
         * item-suggestions
         */
        {
            path: '/item-suggestions/:id/edit',
            name: 'item-suggestions.edit',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_SUGGESTED_ITEM_PROVIDER_VALIDATE',
                    'OS10_SUGGESTED_ITEM_EXTERNAL_USER_VALIDATE',
                ]
            },
            component: () => import('@/components/item-suggestions/forms/ItemSuggestionForm'),
        },

        /**
         * price-consultations
         */
        {
            path: '/price-consultations',
            name: 'price-consultations.index',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_PRICE_CONSULTATION_VIEW',
                ]
            },
            component: () => import('@/components/price-consultations/PriceConsultationPage'),
        },
        {
            path: '/price-consultations/create',
            name: 'price-consultations.create',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_PRICE_CONSULTATION_CREATE',
                ]
            },
            component: () => import('@/components/price-consultations/forms/PriceConsultationForm'),
        },
        {
            path: '/price-consultations/:id/edit',
            name: 'price-consultations.edit',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_PRICE_CONSULTATION_VIEW'
                ]
            },
            component: () => import('@/components/price-consultations/forms/PriceConsultationForm'),
        },
        {
            path: '/price-consultations-emails',
            name: 'price-consultations.emails',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_SEND_PRICE_CONSULTATION_VIEW',
                ]
            },
            component: () => import('@/components/price-consultations/PriceConsultationEmailPage'),
        },

        /**
         * estimates
         */
        {
            path: '/estimates',
            name: 'estimates.index',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_ESTIMATE_VIEW',
                ]
            },
            component: () => import('@/components/estimates/EstimatePage'),
        },
        {
            path: '/price-consultation-estimates',
            name: 'price-consultation.estimates',
            meta: {
                auth: true,
                types: [2],
            },
            component: () => import('@/components/estimates/PriceConsultationEstimatePage'),
        },
        {
            path: '/estimates/create',
            name: 'estimates.create',
            meta: {
                auth: true,
                types: [2],
            },
            component: () => import('@/components/estimates/forms/EstimateForm'),
        },
        {
            path: '/estimates/:id/edit',
            name: 'estimates.edit',
            meta: {
                auth: true,
                types: [1, 2],
                roles: [
                    'OS10_ESTIMATE_VALIDATE',
                ]
            },
            component: () => import('@/components/estimates/forms/EstimateForm'),
        },

        /**
         * electronic auctions
         */
        {
            path: '/electronic-auctions',
            name: 'electronic-auctions.index',
            meta: {
                auth: true,
                types: [1, 3],
                roles: [
                    'OS10_ELECTRONIC_ACTION_VIEW',
                ]
            },
            component: () => import('@/components/electronic-auctions/ElectronicAuctionPage'),
        },
        {
            path: '/electronic-auctions-validate',
            name: 'electronic-auctions.validate',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_ELECTRONIC_ACTION_VALIDATE_VIEW',
                ]
            },
            component: () => import('@/components/electronic-auctions/ElectronicAuctionValidatePage'),
        },
        {
            path: '/electronic-auctions-consult',
            name: 'electronic-auctions.consult',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_ELECTRONIC_ACTION_CONSULT_VIEW',
                ]
            },
            component: () => import('@/components/electronic-auctions/ElectronicAuctionConsultPage'),
        },
        {
            path: '/electronic-auctions/create',
            name: 'electronic-auctions.create',
            meta: {
                auth: true,
                types: [1, 3],
                roles: [
                    'OS10_ELECTRONIC_ACTION_CREATE',
                ]
            },
            component: () => import('@/components/electronic-auctions/forms/ElectronicAuctionForm'),
        },
        {
            path: '/electronic-auctions/:id/edit',
            name: 'electronic-auctions.edit',
            meta: {
                auth: true,
                types: [1, 3],
                roles: [
                    'OS10_ELECTRONIC_ACTION_VIEW'
                ]
            },
            component: () => import('@/components/electronic-auctions/forms/ElectronicAuctionForm'),
        },
        {
            path: '/electronic-auctions/:id/validate',
            name: 'electronic-auctions.edit.validate',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_ELECTRONIC_ACTION_VALIDATE_VIEW',
                ]
            },
            component: () => import('@/components/electronic-auctions/forms/ElectronicAuctionForm'),
        },

        /**
         * price records
         */
        {
            path: '/price-records',
            name: 'price-records.index',
            meta: {
                auth: true,
                types: [1, 2, 3],
                roles: [
                    'OS10_PRICE_RECORD_VIEW',
                ]
            },
            component: () => import('@/components/price-records/PriceRecordPage'),
        },
        {
            path: '/price-records-validate',
            name: 'price-records.validate',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_PRICE_RECORD_VALIDATE_VIEW',
                ]
            },
            component: () => import('@/components/price-records/PriceRecordValidatePage'),
        },
        {
            path: '/price-records-consult',
            name: 'price-records.consult',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_PRICE_RECORD_CONSULT_VIEW',
                ]
            },
            component: () => import('@/components/price-records/PriceRecordConsultPage'),
        },
        {
            path: '/price-records/create',
            name: 'price-records.create',
            meta: {
                auth: true,
                types: [1, 2, 3],
                roles: [
                    'OS10_PRICE_RECORD_CREATE',
                ]
            },
            component: () => import('@/components/price-records/forms/PriceRecordForm'),
        },
        {
            path: '/price-records/:id/edit',
            name: 'price-records.edit',
            meta: {
                auth: true,
                types: [1, 2, 3],
                roles: [
                    'OS10_PRICE_RECORD_VIEW'
                ]
            },
            component: () => import('@/components/price-records/forms/PriceRecordForm'),
        },
        {
            path: '/price-records/:id/validate',
            name: 'price-records.edit.validate',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_PRICE_RECORD_VALIDATE_VALIDATE',
                ]
            },
            component: () => import('@/components/price-records/forms/PriceRecordForm'),
        },

        /**
         * cost definition rules
         */
        {
            path: '/cost-definition-rules',
            name: 'cost-definition-rules.index',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_COST_DEFINITION_RULE_VIEW'
                ]
            },
            component: () => import('@/components/cost-definition-rules/CostDefinitionRulePage'),
        },
        {
            path: '/cost-definition-rule-products',
            name: 'cost-definition-rule-products.index',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_COST_DEFINITION_RULE_PRODUCT_VIEW'
                ]
            },
            component: () => import('@/components/cost-definition-rules/CostDefinitionRuleProductPage'),
        },
        {
            path: '/cost-definition-rules/create',
            name: 'cost-definition-rules.create',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_COST_DEFINITION_RULE_CREATE'
                ]
            },
            component: () => import('@/components/cost-definition-rules/forms/CostDefinitionRuleForm'),
        },
        {
            path: '/cost-definition-rules/:id/edit',
            name: 'cost-definition-rules.edit',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_COST_DEFINITION_RULE_VIEW'
                ]
            },
            component: () => import('@/components/cost-definition-rules/forms/CostDefinitionRuleForm'),
        },

        /**
         * group items
         */
        {
            path: '/group-items',
            name: 'group-items.index',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_GROUP_ITEM_VIEW'
                ]
            },
            component: () => import('@/components/group-items/GroupItemPage'),
        },
        {
            path: '/group-items/create',
            name: 'group-items.create',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_GROUP_ITEM_CREATE'
                ]
            },
            component: () => import('@/components/group-items/forms/GroupItemForm'),
        },
        {
            path: '/group-items/:id/edit',
            name: 'group-items.edit',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_GROUP_ITEM_VIEW'
                ]
            },
            component: () => import('@/components/group-items/forms/GroupItemForm'),
        },

        /**
         * price map
         */
        {
            path: '/price-map',
            name: 'price-map.index',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_PRICE_MAP_VIEW'
                ]
            },
            component: () => import('@/components/price-map/PriceMapPage'),
        },
        {
            path: '/price-map/create',
            name: 'price-map.create',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_PRICE_MAP_CREATE'
                ]
            },
            component: () => import('@/components/price-map/forms/PriceMapForm'),
        },
        {
            path: '/price-map/:id/edit',
            name: 'price-map.edit',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_PRICE_MAP_VIEW'
                ]
            },
            component: () => import('@/components/price-map/forms/PriceMapForm'),
        },

        /**
         * term reference
         */
        {
            path: '/term-reference',
            name: 'term-reference.index',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_TERM_REFERENCE_VIEW'
                ]
            },
            component: () => import('@/components/term-reference/TermReferencePage'),
        },
        {
            path: '/term-reference/create',
            name: 'term-reference.create',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_TERM_REFERENCE_CREATE'
                ]
            },
            component: () => import('@/components/term-reference/forms/TermReferenceForm'),
        },
        {
            path: '/term-reference/:id/edit',
            name: 'term-reference.edit',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_TERM_REFERENCE_VIEW'
                ]
            },
            component: () => import('@/components/term-reference/forms/TermReferenceForm'),
        },

        /**
         * reports
         */
        {
            path: '/report/item-with-supply',
            name: 'report.item-with-supply.index',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_REPORT_ITEM_WITH_SUPPLY_VIEW'
                ]
            },
            component: () => import('@/components/reports/ItemWithSupplyPage'),
        },
        {
            path: '/report/item-without-supply',
            name: 'report.item-without-supply.index',
            meta: {
                auth: true,
                types: [1],
                roles: [
                    'OS10_REPORT_ITEM_WITHOUT_SUPPLY_VIEW'
                ]
            },
            component: () => import('@/components/reports/ItemWithoutSupplyPage'),
        },

    ]
})
