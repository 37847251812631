const state = {};

const mutations = {};

const actions = {
};

const module = {
    state,
    mutations,
    actions
};

export default module;
