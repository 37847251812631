import {Subcategory} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
  getSubcategories(context, pagination) {
    return Subcategory.getSubcategories(pagination).then((response) => {
      return response.data;
    });
  },
  getSubcategory(context, id) {
    return Subcategory.get({id}).then((response) => {
      return response.data;
    });
  },
  createSubcategory(context, item) {
    return Subcategory.create(item).then((response) => {
      return response.data;
    });
  },
  updateSubcategory(context, item) {
    return Subcategory.update(item).then((response) => {
      return response.data;
    });
  },
  destroySubcategory(context, item) {
    return Subcategory.destroy(item).then((response) => {
      return response.data;
    });
  },
  getSubcategoryField(context, item) {
    return Subcategory.getSubcategoryField(item).then((response) => {
      return response.data;
    });
  },
};

const module = {
  state,
  mutations,
  actions
};

export default module;
