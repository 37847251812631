import {ElementsItem} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
  getElementsItem(context, pagination) {
    return ElementsItem.getElementsItem(pagination).then((response) => {
      return response.data;
    });
  },
  getElementItem(context, id) {
    return ElementsItem.get({id}).then((response) => {
      return response.data;
    });
  },
  createElementItem(context, item) {
    return ElementsItem.create(item).then((response) => {
      return response.data;
    });
  },
  updateElementItem(context, item) {
    return ElementsItem.update(item).then((response) => {
      return response.data;
    });
  },
  destroyElementItem(context, item) {
    return ElementsItem.destroy(item).then((response) => {
      return response.data;
    });
  },
  getElementItemField(context, item) {
    return ElementsItem.getElementItemField(item).then((response) => {
      return response.data;
    });
  },
};

const module = {
  state,
  mutations,
  actions
};

export default module;
