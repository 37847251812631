import {ItemSuggestions} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getItemSuggestions(context, pagination) {
        return ItemSuggestions.getItemSuggestions(pagination).then((response) => {
            return response.data;
        });
    },
    getItemSuggestion(context, id) {
        return ItemSuggestions.get({id}).then((response) => {
            return response.data;
        });
    },
    updateItemSuggestion(context, item) {
        return ItemSuggestions.update(item).then((response) => {
            return response.data;
        });
    },
    getItemSuggestionField(context, item) {
        return ItemSuggestions.getItemSuggestionField(item).then((response) => {
            return response.data;
        });
    },
    changeItemSuggestionTemp(context, item) {
        return ItemSuggestions.changeItemSuggestionTemp(item).then((response) => {
            return response.data;
        });
    },
    sendItemSuggestionsEmail(context, item) {
        return ItemSuggestions.sendItemSuggestionsEmail(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
