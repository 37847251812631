<template>
  <v-app>
    <div v-if="showLogout">
      <v-progress-linear
        :indeterminate="true"
        class="my-0"
        color="white"
        height="2"
      ></v-progress-linear>
    </div>

    <v-toolbar
      :extension-height="authenticated ? 120 : 210"
      app
      class="background-toolbar"
      color="primary"
      dark
      dense
      extended
    >
      <template v-if="authenticated">
        <v-toolbar-side-icon
          @click="menuDrawer()"
          v-if="!$vuetify.breakpoint.lgAndUp"
        ></v-toolbar-side-icon>

        <v-toolbar-title>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                @click="$router.push({ name: 'dashboard' })"
                icon
                v-on="on"
              >
                <v-icon
                  :class="$route.name == 'dashboard' ? 'primary--text' : ''"
                  >dashboard</v-icon
                >
              </v-btn>
            </template>
            <span>Página Inicial</span>
          </v-tooltip>

          {{ titlePage }}

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                @click.ctrl="clearFiltersDefault"
                @click="reloadPage"
                icon
                style="margin-left: -2px"
                v-show="titlePage"
                v-on="on"
              >
                <v-icon>refresh</v-icon>
              </v-btn>
            </template>
            <span>Atualizar Página</span>
          </v-tooltip>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-tooltip bottom v-if="user.tipoUsuario == 1">
          <v-btn
            icon
            slot="activator"
            @click="$router.push({ name: 'access-profile.request' })"
          >
            <v-badge color="red" overlap>
              <v-icon>sync_lock</v-icon>
            </v-badge>
          </v-btn>
          <span> Solicitar perfil de acesso ao sistema </span>
        </v-tooltip>

        <v-tooltip bottom>
          <v-btn
            @click.stop="notificationsMenu = !notificationsMenu"
            icon
            slot="activator"
          >
            <v-badge color="red" overlap>
              <span slot="badge" v-if="notifications.total">{{
                notifications.total
              }}</span>
              <v-icon>notifications</v-icon>
            </v-badge>
          </v-btn>
          <span v-show="!notifications.total">Não há notificações</span>
          <span v-show="notifications.total"
            >Há
            {{
              notifications.total > 1
                ? `${notifications.total} notificações`
                : `${notifications.total} notificação`
            }}</span
          >
        </v-tooltip>

        <v-menu
          :close-on-content-click="false"
          :nudge-width="150"
          button
          left
          v-model="menu"
        >
          <v-btn icon slot="activator">
            <v-icon>more_vert</v-icon>
          </v-btn>
          <v-card>
            <v-list>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>{{ user.firstName }}</v-list-tile-title>
                  <v-list-tile-sub-title style="min-width: 100px"
                    >{{ user.lastName }}
                  </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action></v-list-tile-action>
              </v-list-tile>
            </v-list>
            <v-divider></v-divider>
            <v-list>
              <v-list-tile
                :key="item.to"
                :to="{ name: item.to }"
                @click="menu = false"
                v-for="item in menuUser"
              >
                <v-list-tile-title v-text="item.title"></v-list-tile-title>
              </v-list-tile>
            </v-list>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="
                  menu = false;
                  logout();
                "
                color="red"
                flat
                >Sair</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-menu>
      </template>
    </v-toolbar>

    <menu-navigation
      :authenticated="menuCheck"
      :user="user"
      ref="referenceMenu"
      v-if="checkMenu('default')"
    ></menu-navigation>

    <menu-navigation-provider
      :authenticated="menuCheck"
      :user="user"
      ref="referenceMenu"
      v-if="checkMenu('provider')"
    ></menu-navigation-provider>

    <menu-navigation-external-user
      :authenticated="menuCheck"
      :user="user"
      ref="referenceMenu"
      v-if="checkMenu('external-user')"
    ></menu-navigation-external-user>

    <v-navigation-drawer
      :right="true"
      app
      fixed
      temporary
      v-model="notificationsMenu"
      width="300"
    >
      <v-toolbar class="primary" dark flat prominent>
        <v-toolbar-title>Notificações</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click.stop="notificationsMenu = false" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-list two-line>
        <v-subheader>Todas notificações</v-subheader>

        <template v-for="(notification, index) in notifications.items">
          <v-list-tile :key="index" :to="{ name: notification.link }">
            <v-list-tile-content>
              <v-list-tile-title>{{ notification.title }}</v-list-tile-title>
              <v-list-tile-sub-title>{{
                notification.subTitle
              }}</v-list-tile-sub-title>
              <v-list-tile-sub-title v-if="notification.info"
                ><small>{{ notification.info }}</small>
              </v-list-tile-sub-title>
            </v-list-tile-content>

            <v-list-tile-action>
              <v-list-tile-action-text>Total</v-list-tile-action-text>
              <v-chip
                :color="notification.total > 0 ? 'primary' : 'grey'"
                text-color="white"
                small
              >
                {{ notification.total }}
              </v-chip>
            </v-list-tile-action>
          </v-list-tile>

          <v-divider
            v-if="index + 1 < notifications.items.length"
            :key="index + 1000"
          ></v-divider>
        </template>
      </v-list>
    </v-navigation-drawer>

    <main>
      <v-content>
        <v-container fluid grid-list-md>
          <v-slide-y-transition mode="out-in">
            <router-view
              @getNotifications="getNotifications"
              @menuDrawer="menuDrawer"
              @titlePage="updateTitlePage"
            ></router-view>
          </v-slide-y-transition>
        </v-container>

        <v-dialog v-model="dialogConfirm" persistent max-width="290">
          <v-card>
            <v-card-text>
              <div v-html="confirmMessage"></div>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" flat @click="eventConfirm()">Ok</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogExpired" persistent max-width="400">
          <v-card>
            <v-card-text>
              <div v-html="textExpired"></div>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-grey darken-4" flat @click="closeExpired"
                >Não</v-btn
              >
              <v-btn
                color="primary"
                flat
                :loading="loadingExpired"
                @click="confirmExpired"
                >Sim</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-content>
    </main>

    <v-footer
      app
      class="white--text"
      color="secondary pa-3"
      v-if="!authenticated"
    >
      <v-spacer></v-spacer>
      <v-layout justify-center row wrap>
        <v-flex py-2 text-xs-right white--text xs12>
          Copyright <span>&copy;</span> {{ new Date().getFullYear() }}
        </v-flex>
      </v-layout>
    </v-footer>
  </v-app>
</template>

<script>
import MenuNavigationExternalUser from "@/components/app/MenuNavigationExternalUser";
import MenuNavigationProvider from "@/components/app/MenuNavigationProvider";
import moment from "moment";
import MenuNavigation from "./components/app/MenuNavigation";
import LocalStorage from "./services/localStorage";
import store from "./store/store";

export default {
  components: {
    MenuNavigation,
    MenuNavigationProvider,
    MenuNavigationExternalUser,
  },
  data() {
    return {
      notificationsMenu: false,
      titlePage: "",
      showLogout: false,
      menuUser: [],
      menu: false,
      message: false,
      hints: true,
      trial: 0,
      notifications: {
        total: 0,
        items: [],
      },

      dialogExpired: false,
      loadingExpired: false,
      textExpired: "",

      dialogConfirm: false,
      confirmMessage: "",
    };
  },
  watch: {
    $route(to, from) {
      let variants = ["login", "register", "reset.password"];

      if (variants.includes(from.name) && this.authenticated) {
        store.commit("menuCheck");
      }

      this.titlePage = "";
    },
    authenticated: function (auth) {
      if (auth) {
        this.getNotifications();
        this.getInformation();
      }
    },
    user() {
      if (this.authenticated) {
        /**
         * Verifica se o usuário externo se encontra expidado, caso sim apresente modal solicitando renovação.
         */
        if (this.user.tipoUsuario == 3) {
          if (this.user.external_user.data_lim_acesso) {
            let now = moment().format("YYYY-MM-DD");
            let limit_date = moment(
              this.user.external_user.data_lim_acesso
            ).format("YYYY-MM-DD");

            if (now > limit_date) {
              if (this.user.external_user.situacao) {
                this.textExpired =
                  "O acesso ao sistema foi desativado, devido a expiração da data de validade de acesso concedida (" +
                  moment(this.user.external_user.data_lim_acesso).format(
                    "DD/MM/YYYY"
                  ) +
                  "). <br><br> Deseja reativação do acesso?";
                this.dialogExpired = true;
              } else {
                this.confirmMessage =
                  "Usuário está pendente de aprovação. Será necessário aguardar a aprovação por parte do CBMMG, para acessar o sistema.";
                this.dialogConfirm = true;
              }
            }
          }
        }
      }
    },
  },
  mounted: function () {
    let self = this;
    window.addEventListener("storage", function (event) {
      if (event.key === "token" && !event.newValue) {
        store.dispatch("clearAuth");
        self.$router.push({ name: "login" });
      }
    });

    store.dispatch("getAPPVersion").then((response) => {
      if (response !== process.env.VUE_APP_VERSION) {
        if (
          parseInt(response.replace(/[^0-9]/g, "")) >
          parseInt(process.env.VUE_APP_VERSION.replace(/[^0-9]/g, ""))
        ) {
          this.clearFiltersDefault();

          window.location.reload();
        }
      }
    });

    this.chat();

    if (this.authenticated) {
      this.getNotifications();
      this.getInformation();
    }
  },
  computed: {
    authenticated() {
      return store.state.auth.check;
    },
    menuCheck() {
      return store.state.auth.menuCheck;
    },
    user() {
      let user = store.state.auth.user;

      return user ? user : "";
    },
  },
  methods: {
    chat() {},
    reloadPage() {
      window.location.reload();
    },
    updateTitlePage(titlePage) {
      this.titlePage = titlePage;
    },
    menuDrawer(force = false, status = false) {
      if (force) {
        return (this.$refs.referenceMenu.drawer = status);
      }

      return (this.$refs.referenceMenu.drawer =
        !this.$refs.referenceMenu.drawer);
    },
    checkMenu(type) {
      switch (type) {
        case "default":
          return this.user.tipoUsuario == 1;
        case "provider":
          return this.user.tipoUsuario == 2;
        case "external-user":
          return this.user.tipoUsuario == 3;
      }

      return false;
    },
    getNotifications() {
      store.dispatch("getUserNotifications").then((response) => {
        response = response.data;

        this.notifications = {
          total: response.total,
          items: response.items,
        };
      });
    },
    getInformation() {
      if (this.checkMenu("default")) {
        // this.menuUser = [
        //   {
        //     title: 'Perfil',
        //     to: 'profile',
        //   },
        // ];
      }

      if (this.checkMenu("provider")) {
        // this.menuUser = [
        //   {
        //     title: 'Perfil',
        //     to: 'profile',
        //   },
        // ];
      }

      if (this.checkMenu("external-user")) {
        // this.menuUser = [
        //   {
        //     title: 'Perfil',
        //     to: 'profile',
        //   },
        // ];
      }
    },
    logout(redirectUri = "") {
      this.showLogout = true;

      let goToLogin = () => {
        LocalStorage.remove("token");
        LocalStorage.remove("refresh_token");

        for (let item in LocalStorage) {
          if (item.match(/search_/) || item.match(/headers_/)) {
            LocalStorage.removeItem(item);
          }
        }

        this.showLogout = false;

        if (redirectUri === "") {
          if (this.user.provider_expired)
            redirectUri = window.location.origin + "/register-provider";
        }

        store.dispatch("clearAuth");

        this.$keycloak.logout({ redirectUri });
      };

      store.dispatch("logout").then(goToLogin).catch(goToLogin);
    },
    clearFiltersDefault() {
      for (let item in localStorage) {
        if (item.match(/search_/) || item.match(/headers_/)) {
          localStorage.removeItem(item);
        }
      }
    },

    closeExpired() {
      this.logout();
    },
    async confirmExpired() {
      this.loadingExpired = true;

      await store.dispatch("requestAccessRenewalExternalUsers").then(() => {
        this.loadingExpired = false;

        this.confirmMessage =
          "A solicitação foi registrada e será avaliada. <br> Caso a solicitação seja aprovada, será enviado o e-mail informando a liberação ao sistema.";
        this.dialogConfirm = true;
      });
    },
    eventConfirm() {
      this.logout();
    },
  },
};
</script>

<style>
@import "@/assets/styles/app.css";
</style>
