import {GroupItems} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getGroupItems(context, pagination) {
        return GroupItems.getGroupItems(pagination).then((response) => {
            return response.data;
        });
    },
    getGroupItem(context, id) {
        return GroupItems.get({id}).then((response) => {
            return response.data;
        });
    },
    createGroupItem(context, item) {
        return GroupItems.create(item).then((response) => {
            return response.data;
        });
    },
    updateGroupItem(context, item) {
        return GroupItems.update(item).then((response) => {
            return response.data;
        });
    },
    destroyGroupItem(context, item) {
        return GroupItems.destroy(item).then((response) => {
            return response.data;
        });
    },
    getGroupItemField(context, item) {
        return GroupItems.getGroupItemField(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};
export default module;
