import {CostDefinitionRules} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getCostDefinitionRules(context, pagination) {
        return CostDefinitionRules.getCostDefinitionRules(pagination).then((response) => {
            return response.data;
        });
    },
    getCostDefinitionRule(context, id) {
        return CostDefinitionRules.get({id}).then((response) => {
            return response.data;
        });
    },
    createCostDefinitionRule(context, item) {
        return CostDefinitionRules.create(item).then((response) => {
            return response.data;
        });
    },
    updateCostDefinitionRule(context, item) {
        return CostDefinitionRules.update(item).then((response) => {
            return response.data;
        });
    },
    destroyCostDefinitionRule(context, item) {
        return CostDefinitionRules.destroy(item).then((response) => {
            return response.data;
        });
    },
    getCostDefinitionRuleField(context, item) {
        return CostDefinitionRules.getCostDefinitionRuleField(item).then((response) => {
            return response.data;
        });
    },
    updateCostDefinitionRuleProduct(context, item) {
        return CostDefinitionRules.updateCostDefinitionRuleProduct(item).then((response) => {
            return response.data;
        });
    },
    calculateAverageCostRule(context, product_id, average_cost_rule_id) {
        return CostDefinitionRules.calculateAverageCostRule(product_id, average_cost_rule_id).then((response) => {
            return response.data;
        });
    },
    calculateLowestCostRule(context, product_id, lowest_cost_rule_id) {
        return CostDefinitionRules.calculateLowestCostRule(product_id, lowest_cost_rule_id).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
