import {SupplyProductHeaders} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getSupplyProductHeaders(context, pagination) {
        return SupplyProductHeaders.getSupplyProductHeaders(pagination).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
