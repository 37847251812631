<template>
  <v-navigation-drawer
    :mini-variant="miniVariant"
    app
    class="blue-grey darken-4"
    dark
    fixed
    persistent
    v-if="authenticated"
    v-model="drawer"
    width="300"
  >
    <v-toolbar class="transparent" flat>
      <v-list :class="{ 'list-border-bottom': miniVariant }" class="pa-0">
        <v-list-tile>
          <v-list-tile-content v-if="!miniVariant">
            <div style="position: absolute; height: 100%" v-if="!miniVariant">
              <router-link
                :to="{ name: 'dashboard' }"
                class="white--text"
                style="text-decoration: none"
              >
                <img
                  src="../../assets/logo.png"
                  class="center"
                  style="height: 100%"
                />
              </router-link>
            </div>
          </v-list-tile-content>
          <v-list-tile-action style="margin-right: -10px">
            <v-btn @click.stop="miniVariant = !miniVariant" icon>
              <v-icon
                v-html="miniVariant ? 'chevron_right' : 'chevron_left'"
              ></v-icon>
            </v-btn>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>
    </v-toolbar>

    <v-divider v-if="List1.length"></v-divider>
    <v-list subheader v-if="List1.length">
      <v-subheader>Itens do Catálogo</v-subheader>
      <template v-for="(item, index) in List1">
        <v-tooltip :disabled="!miniVariant" right :key="index">
          <v-list-tile
            :key="item.icon"
            :to="item.link ? { name: item.link } : null"
            exact
            slot="activator"
          >
            <v-list-tile-action>
              <v-icon v-html="item.icon"></v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title v-text="item.title"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <span v-text="item.title"></span>
        </v-tooltip>
      </template>
    </v-list>

    <v-divider v-if="List2.length"></v-divider>
    <v-list subheader v-if="List2.length">
      <v-subheader>Sugerir Item</v-subheader>
      <template v-for="(item, index) in List2">
        <v-tooltip :disabled="!miniVariant" right :key="index">
          <v-list-tile
            :key="item.icon"
            :to="{ name: item.link }"
            exact
            slot="activator"
          >
            <v-list-tile-action>
              <v-icon v-html="item.icon"></v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title v-text="item.title"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <span v-text="item.title"></span>
        </v-tooltip>
      </template>
    </v-list>

    <v-divider v-if="List3.length"></v-divider>
    <v-list subheader v-if="List3.length">
      <v-subheader>Fornecedores</v-subheader>
      <template v-for="(item, index) in List3">
        <v-tooltip :disabled="!miniVariant" right :key="index">
          <v-list-tile
            :key="item.icon"
            :to="{ name: item.link }"
            exact
            slot="activator"
          >
            <v-list-tile-action>
              <v-icon v-html="item.icon"></v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title v-text="item.title"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <span v-text="item.title"></span>
        </v-tooltip>
      </template>
    </v-list>

    <v-divider v-if="List4.length"></v-divider>
    <v-list subheader v-if="List4.length">
      <v-subheader>Usuários Externo</v-subheader>
      <template v-for="(item, index) in List4">
        <v-tooltip :disabled="!miniVariant" right :key="index">
          <v-list-tile
            :key="item.icon"
            :to="{ name: item.link }"
            exact
            slot="activator"
          >
            <v-list-tile-action>
              <v-icon v-html="item.icon"></v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title v-text="item.title"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <span v-text="item.title"></span>
        </v-tooltip>
      </template>
    </v-list>

    <v-divider v-if="List7.length"></v-divider>
    <v-list subheader v-if="List7.length">
      <v-subheader>Atas Pregões Eletrônicos</v-subheader>
      <template v-for="(item, index) in List7">
        <v-tooltip :disabled="!miniVariant" right :key="index">
          <v-list-tile
            :key="item.icon"
            :to="{ name: item.link }"
            exact
            slot="activator"
          >
            <v-list-tile-action>
              <v-icon v-html="item.icon"></v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title v-text="item.title"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <span v-text="item.title"></span>
        </v-tooltip>
      </template>
    </v-list>

    <v-divider v-if="List8.length"></v-divider>
    <v-list subheader v-if="List8.length">
      <v-subheader>Atas Registro de Preços</v-subheader>
      <template v-for="(item, index) in List8">
        <v-tooltip :disabled="!miniVariant" right :key="index">
          <v-list-tile
            :key="item.icon"
            :to="{ name: item.link }"
            exact
            slot="activator"
          >
            <v-list-tile-action>
              <v-icon v-html="item.icon"></v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title v-text="item.title"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <span v-text="item.title"></span>
        </v-tooltip>
      </template>
    </v-list>

    <v-divider v-if="List9.length"></v-divider>
    <v-list subheader v-if="List9.length">
      <v-subheader>Processo de Compras</v-subheader>
      <template v-for="(item, index) in List9">
        <v-tooltip :disabled="!miniVariant" right :key="index">
          <v-list-tile
            :key="item.icon"
            :to="{ name: item.link }"
            exact
            slot="activator"
          >
            <v-list-tile-action>
              <v-icon v-html="item.icon"></v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title v-text="item.title"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <span v-text="item.title"></span>
        </v-tooltip>
      </template>
    </v-list>

    <v-divider v-if="List10.length"></v-divider>
    <v-list subheader v-if="List10.length">
      <v-subheader>Relatórios</v-subheader>
      <template v-for="(item, index) in List10">
        <v-tooltip :disabled="!miniVariant" right :key="index">
          <v-list-tile
            :key="item.icon"
            :to="{ name: item.link }"
            exact
            slot="activator"
          >
            <v-list-tile-action>
              <v-icon v-html="item.icon"></v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title v-text="item.title"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <span v-text="item.title"></span>
        </v-tooltip>
      </template>
    </v-list>

    <v-divider v-if="List5.length"></v-divider>
    <v-list subheader v-if="List5.length">
      <v-subheader>Controle de Acesso</v-subheader>
      <template v-for="(item, index) in List5">
        <v-tooltip :disabled="!miniVariant" right :key="index">
          <v-list-tile
            :key="item.icon"
            :to="{ name: item.link }"
            exact
            slot="activator"
          >
            <v-list-tile-action>
              <v-icon v-html="item.icon"></v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title v-text="item.title"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <span v-text="item.title"></span>
        </v-tooltip>
      </template>
    </v-list>

    <v-divider v-if="List6.length"></v-divider>
    <v-list subheader v-if="List6.length">
      <v-subheader>Gestão</v-subheader>
      <template v-for="(item, index) in List6">
        <v-tooltip :disabled="!miniVariant" right :key="index">
          <v-list-tile
            :key="item.icon"
            :to="{ name: item.link }"
            exact
            slot="activator"
          >
            <v-list-tile-action>
              <v-icon v-html="item.icon"></v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title v-text="item.title"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <span v-text="item.title"></span>
        </v-tooltip>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: ["authenticated", "user"],
  data() {
    return {
      drawer: true,
      miniVariant: false,

      List1: [],
      List2: [],
      List3: [],
      List4: [],
      List5: [],
      List6: [],
      List7: [],
      List8: [],
      List9: [],
      List10: [],
    };
  },
  watch: {
    $route: function (next, previous) {
      let variants = [];

      let variant = variants.includes(next.name);

      if (variants.includes(previous.name) && variants.includes(next.name)) {
        variant = true;
      }

      this.miniVariant = variant;
    },
  },
  methods: {},
  computed: {},
  created() {
    // List1
    if (this.$keycloak.hasRealmRole("OS10_ITEM_VIEW")) {
      this.List1.push({
        icon: "inventory",
        title: "Itens do Catálogo",
        link: "products.index",
      });
    }
    if (this.$keycloak.hasRealmRole("OS10_ATTRIBUTE_ITEM_VIEW")) {
      this.List1.push({
        icon: "format_strikethrough",
        title: "Atributos Específicos",
        link: "attributes-items.index",
      });
    }
    if (this.$keycloak.hasRealmRole("OS10_ELEMENT_ITEM_VIEW")) {
      this.List1.push({
        icon: "workspaces",
        title: "Elemento Item",
        link: "elements-items.index",
      });
    }
    if (this.$keycloak.hasRealmRole("OS10_TAG_VIEW")) {
      this.List1.push({
        icon: "sell",
        title: "Tags",
        link: "tags.index",
      });
    }
    if (this.$keycloak.hasRealmRole("OS10_CATEGORY_VIEW")) {
      this.List1.push({
        icon: "category",
        title: "Categorias",
        link: "categories.index",
      });
    }
    if (this.$keycloak.hasRealmRole("OS10_SUBCATEGORY_VIEW")) {
      this.List1.push({
        icon: "dynamic_feed",
        title: "Subcategorias",
        link: "subcategories.index",
      });
    }
    if (this.$keycloak.hasRealmRole("OS10_ACQUISITION_UNIT_VIEW")) {
      this.List1.push({
        icon: "local_library",
        title: "Unidades de Aquisição",
        link: "acquisition-units.index",
      });
    }
    if (this.$keycloak.hasRealmRole("OS10_PURCHASE_MANAGEMENT_VIEW")) {
      this.List1.push({
        icon: "auto_stories",
        title: "Gerência de Compras",
        link: "purchase-managements.index",
      });
    }
    if (this.$keycloak.hasRealmRole("OS10_USERS_PURCHASE_MANAGEMENT_VIEW")) {
      this.List1.push({
        icon: "people_alt",
        title: "Usuários da Gerência",
        link: "users-purchase-management.index",
      });
    }

    // List2
    if (this.$keycloak.hasRealmRole("OS10_REQUEST_ITEM_SUGGESTION_VIEW")) {
      this.List2.push({
        icon: "assistant_photo",
        title: "Solicitação Sugestão Item",
        link: "request-item-suggestions.index",
      });
    }
    if (
      this.$keycloak.hasRealmRole(
        "OS10_SEND_REQUEST_ITEM_SUGGESTION_PROVIDER_VIEW"
      )
    ) {
      this.List2.push({
        icon: "outgoing_mail",
        title: "Envio Solicit. Fornecedor",
        link: "item-suggestions-request-email.providers",
      });
    }
    if (
      this.$keycloak.hasRealmRole(
        "OS10_SEND_REQUEST_ITEM_SUGGESTION_EXTERNAL_USER_VIEW"
      )
    ) {
      this.List2.push({
        icon: "outgoing_mail",
        title: "Envio Solicit. Usuário Externo",
        link: "item-suggestions-request-email.external-users",
      });
    }

    // List3
    if (this.$keycloak.hasRealmRole("OS10_PROVIDER_VIEW")) {
      this.List3.push({
        icon: "business",
        title: "Fornecedores",
        link: "providers.index",
      });
    }
    if (this.$keycloak.hasRealmRole("OS10_PRICE_CONSULTATION_VIEW")) {
      this.List3.push({
        icon: "query_stats",
        title: "Consultas de Preços",
        link: "price-consultations.index",
      });
    }
    if (this.$keycloak.hasRealmRole("OS10_SEND_PRICE_CONSULTATION_VIEW")) {
      this.List3.push({
        icon: "outgoing_mail",
        title: "Envio Consultas de Preços",
        link: "price-consultations.emails",
      });
    }
    if (this.$keycloak.hasRealmRole("OS10_ESTIMATE_VIEW")) {
      this.List3.push({
        icon: "request_quote",
        title: "Orçamentos",
        link: "estimates.index",
      });
    }
    if (this.$keycloak.hasRealmRole("OS10_SUGGESTED_ITEM_PROVIDER_VIEW")) {
      this.List3.push({
        icon: "precision_manufacturing",
        title: "Itens Sugeridos",
        link: "suggested-items.providers",
      });
    }
    if (this.$keycloak.hasRealmRole("OS10_SUPPLIED_PRODUCTS_PROVIDER_VIEW")) {
      this.List3.push({
        icon: "interests",
        title: "Lista de Itens de Interesse",
        link: "supplied-products.providers.index",
      });
    }

    // List4
    if (this.$keycloak.hasRealmRole("OS10_EXTERNAL_USER_VIEW")) {
      this.List4.push({
        icon: "person_pin",
        title: "Usuários Externo",
        link: "external-users.index",
      });
    }
    if (this.$keycloak.hasRealmRole("OS10_SUGGESTED_ITEM_EXTERNAL_USER_VIEW")) {
      this.List4.push({
        icon: "precision_manufacturing",
        title: "Itens Sugeridos",
        link: "suggested-items.external-users",
      });
    }
    if (
      this.$keycloak.hasRealmRole("OS10_SUPPLIED_PRODUCTS_EXTERNAL_USER_VIEW")
    ) {
      this.List4.push({
        icon: "interests",
        title: "Lista de Itens de Interesse",
        link: "supplied-products.external-users.index",
      });
    }

    // List5
    if (this.$keycloak.hasRealmRole("OS10_ACCESS_PROFILE_VIEW")) {
      this.List5.push({
        icon: "admin_panel_settings",
        title: "Perfis de Acesso",
        link: "access-profile.index",
      });
    }
    if (this.$keycloak.hasRealmRole("OS10_USERS_ACCESS_PROFILE_VIEW")) {
      this.List5.push({
        icon: "engineering",
        title: "Perfis de Acesso dos Usuários",
        link: "users-access-profile.index",
      });
    }

    // List6
    if (this.$keycloak.hasRealmRole("OS10_CONFIG_VIEW")) {
      this.List6.push({
        icon: "settings",
        title: "Configuração Sistema",
        link: "config.index",
      });
    }

    // List7
    if (this.$keycloak.hasRealmRole("OS10_ELECTRONIC_ACTION_VIEW")) {
      this.List7.push({
        icon: "hardware",
        title: "Atas Pregões Eletrônicos",
        link: "electronic-auctions.index",
      });
    }
    if (this.$keycloak.hasRealmRole("OS10_ELECTRONIC_ACTION_VALIDATE_VIEW")) {
      this.List7.push({
        icon: "fact_check",
        title: "Validar Atas Pregões Eletrônicos",
        link: "electronic-auctions.validate",
      });
    }
    if (this.$keycloak.hasRealmRole("OS10_ELECTRONIC_ACTION_CONSULT_VIEW")) {
      this.List7.push({
        icon: "manage_search",
        title: "Consultar Atas PE dos Itens",
        link: "electronic-auctions.consult",
      });
    }

    // List8
    if (this.$keycloak.hasRealmRole("OS10_PRICE_RECORD_VIEW")) {
      this.List8.push({
        icon: "receipt_long",
        title: "Atas Registro Preços",
        link: "price-records.index",
      });
    }
    if (this.$keycloak.hasRealmRole("OS10_PRICE_RECORD_VALIDATE_VIEW")) {
      this.List8.push({
        icon: "fact_check",
        title: "Validar Atas Registro Preços",
        link: "price-records.validate",
      });
    }
    if (this.$keycloak.hasRealmRole("OS10_PRICE_RECORD_CONSULT_VIEW")) {
      this.List8.push({
        icon: "manage_search",
        title: "Consultar Atas RP dos Itens",
        link: "price-records.consult",
      });
    }

    // List9
    if (this.$keycloak.hasRealmRole("OS10_COST_DEFINITION_RULE_VIEW")) {
      this.List9.push({
        icon: "price_check",
        title: "Regras Definição Custo",
        link: "cost-definition-rules.index",
      });
    }
    if (this.$keycloak.hasRealmRole("OS10_COST_DEFINITION_RULE_PRODUCT_VIEW")) {
      this.List9.push({
        icon: "price_change",
        title: "Definição Custo Itens Catálogo",
        link: "cost-definition-rule-products.index",
      });
    }
    if (this.$keycloak.hasRealmRole("OS10_GROUP_ITEM_VIEW")) {
      this.List9.push({
        icon: "splitscreen",
        title: "Grupo Itens do Catálogo",
        link: "group-items.index",
      });
    }
    if (this.$keycloak.hasRealmRole("OS10_PRICE_MAP_VIEW")) {
      this.List9.push({
        icon: "pivot_table_chart",
        title: "Mapa de Preços",
        link: "price-map.index",
      });
    }
    if (this.$keycloak.hasRealmRole("OS10_TERM_REFERENCE_VIEW")) {
      this.List9.push({
        icon: "auto_stories",
        title: "Termos de Referência",
        link: "term-reference.index",
      });
    }

    // List 10
    if (this.$keycloak.hasRealmRole("OS10_REPORT_ITEM_WITH_SUPPLY_VIEW")) {
      this.List10.push({
        icon: "flag",
        title: "Itens com Interesse Fornecimento",
        link: "report.item-with-supply.index",
      });
    }

    if (this.$keycloak.hasRealmRole("OS10_REPORT_ITEM_WITHOUT_SUPPLY_VIEW")) {
      this.List10.push({
        icon: "report_off",
        title: "Itens sem Interesse Fornecimento",
        link: "report.item-without-supply.index",
      });
    }
  },
};
</script>

<style scoped>
.v-navigation-drawer--mini-variant {
  overflow: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 5px;
  border-left: 1px solid hsla(0, 0%, 100%, 0.12);
}

::-webkit-scrollbar * {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #b0bec5 !important;
  border-radius: 10px;
}
</style>
