import {ExternalUsers} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getExternalUsers(context, pagination) {
        return ExternalUsers.getExternalUsers(pagination).then((response) => {
            return response.data;
        });
    },
    getExternalUser(context, id) {
        return ExternalUsers.get({id}).then((response) => {
            return response.data;
        });
    },
    createExternalUser(context, item) {
        return ExternalUsers.create(item).then((response) => {
            return response.data;
        });
    },
    updateExternalUser(context, item) {
        return ExternalUsers.update(item).then((response) => {
            return response.data;
        });
    },
    createExternalUserPublic(context, item) {
        return ExternalUsers.createPublic(item).then((response) => {
            return response.data;
        });
    },
    updateExternalUserPublic(context, item) {
        return ExternalUsers.updatePublic(item).then((response) => {
            return response.data;
        });
    },
    destroyExternalUser(context, item) {
        return ExternalUsers.destroy(item).then((response) => {
            return response.data;
        });
    },
    getExternalUserField(context, item) {
        return ExternalUsers.getExternalUserField(item).then((response) => {
            return response.data;
        });
    },
    sendExternalUsersEmail(context, item) {
        return ExternalUsers.sendExternalUsersEmail(item).then((response) => {
            return response.data;
        });
    },
    approveExternalUsers(context, item) {
        return ExternalUsers.approveExternalUsers(item).then((response) => {
            return response.data;
        });
    },
    requestAccessRenewalExternalUsers(context, item) {
        return ExternalUsers.requestAccessRenewalExternalUsers(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;