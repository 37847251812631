import {Products} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
  getProducts(context, pagination) {
    return Products.getProducts(pagination).then((response) => {
      return response.data;
    });
  },
  getProduct(context, id) {
    return Products.get({id}).then((response) => {
      return response.data;
    });
  },
  createProduct(context, product) {
    return Products.create(product).then((response) => {
      return response.data;
    });
  },
  updateProduct(context, product) {
    return Products.update(product).then((response) => {
      return response.data;
    });
  },
  destroyProduct(context, product) {
    return Products.destroy(product).then((response) => {
      return response.data;
    });
  },
  getHistories(context, product) {
    return Products.getHistories(product).then((response) => {
      return response.data;
    });
  },
  getProductField(context, item) {
    return Products.getProductField(item).then((response) => {
      return response.data;
    });
  },
  changeProductSupply(context, product) {
    return Products.changeProductSupply(product).then((response) => {
      return response.data;
    });
  },
};

const module = {
  state,
  mutations,
  actions
};

export default module;
