import {Estimates} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
  getEstimates(context, pagination) {
    return Estimates.getEstimates(pagination).then((response) => {
      return response.data;
    });
  },
  getEstimate(context, id) {
    return Estimates.get({id}).then((response) => {
      return response.data;
    });
  },
  createEstimate(context, item) {
    return Estimates.create(item).then((response) => {
      return response.data;
    });
  },
  updateEstimate(context, item) {
    return Estimates.update(item).then((response) => {
      return response.data;
    });
  },
  destroyEstimate(context, item) {
    return Estimates.destroy(item).then((response) => {
      return response.data;
    });
  },
  getEstimateField(context, item) {
    return Estimates.getEstimateField(item).then((response) => {
      return response.data;
    });
  },
  statusEstimates(context, item) {
    return Estimates.statusEstimates(item).then((response) => {
      return response.data;
    });
  },
  statusEstimateItems(context, item) {
    return Estimates.statusEstimateItems(item).then((response) => {
      return response.data;
    });
  },
  sendEstimatesEmail(context, item) {
    return Estimates.sendEstimatesEmail(item).then((response) => {
      return response.data;
    });
  },
};

const module = {
  state,
  mutations,
  actions
};

export default module;
