import {PriceMaps} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getPriceMaps(context, pagination) {
        return PriceMaps.getPriceMaps(pagination).then((response) => {
            return response.data;
        });
    },
    getPriceMap(context, id) {
        return PriceMaps.get({id}).then((response) => {
            return response.data;
        });
    },
    createPriceMap(context, item) {
        return PriceMaps.create(item).then((response) => {
            return response.data;
        });
    },
    updatePriceMap(context, item) {
        return PriceMaps.update(item).then((response) => {
            return response.data;
        });
    },
    destroyPriceMap(context, item) {
        return PriceMaps.destroy(item).then((response) => {
            return response.data;
        });
    },
    getPriceMapField(context, item) {
        return PriceMaps.getPriceMapField(item).then((response) => {
            return response.data;
        });
    },
    getPriceMapDocuments(context, pagination) {
        return PriceMaps.getPriceMapDocuments(pagination).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};
export default module;
