import JwtTokenService from "../services/jwtToken";
import LocalStorageService from "../services/localStorage";
import {Jwt} from '../services/resources';

const userLocalStorage = 'user';
const state = {
    user: LocalStorageService.getObject(userLocalStorage),
    check: JwtTokenService.token != null,
    menuCheck: JwtTokenService.token != null
};

const mutations = {
    setUser(state, user) {
        state.user = user;
        if (user !== null) {
            LocalStorageService.setObject(userLocalStorage, user);
        } else {
            LocalStorageService.remove(userLocalStorage);
        }
    },
    authenticated(state) {
        state.check = true;
    },
    unauthenticated(state) {
        state.check = false;
    },
    menuCheck(state) {
        state.menuCheck = true;
    },
    unMenuCheck(state) {
        state.menuCheck = false;
    }
};

const actions = {
    login(context, {email, password, mode = false}) {
        return JwtTokenService.accessToken(email, password, mode).then(() => {
            return context.dispatch('getUser').then((response) => {
                response = response.data;

                if (response.id) {
                    context.commit('authenticated');
                } else {
                    context.dispatch('logout');
                }

                return response;
            });
        });
    },
    register(context, fields) {
        return Jwt.register(fields).then((response) => {
            return response;
        })
    },
    sendResetPassword(context, fields) {
        return Jwt.sendResetPassword(fields).then((response) => {
            return response;
        })
    },
    resetPassword(context, fields) {
        return Jwt.resetPassword(fields).then((response) => {
            return response;
        })
    },
    logout() {
        let afterLogout = (response) => {
            return response;
        };

        return JwtTokenService.revokeToken()
            .then(afterLogout)
            .catch(afterLogout);
    },
    clearAuth(context) {
        context.commit('unauthenticated');
        context.commit('unMenuCheck');
        context.commit('setUser', null);
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
