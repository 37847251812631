import {Tags} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
  getTags(context, pagination) {
    return Tags.getTags(pagination).then((response) => {
      return response.data;
    });
  },
  getTag(context, id) {
    return Tags.get({id}).then((response) => {
      return response.data;
    });
  },
  createTag(context, item) {
    return Tags.create(item).then((response) => {
      return response.data;
    });
  },
  updateTag(context, item) {
    return Tags.update(item).then((response) => {
      return response.data;
    });
  },
  destroyTag(context, item) {
    return Tags.destroy(item).then((response) => {
      return response.data;
    });
  },
  getTagField(context, item) {
    return Tags.getTagField(item).then((response) => {
      return response.data;
    });
  },
};

const module = {
  state,
  mutations,
  actions
};

export default module;
