import {Check} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getAPPVersion(context, data) {
        return Check.getAPPVersion(data).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
