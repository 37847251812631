import {AcquisitionUnits} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
  getAcquisitionUnits(context, pagination) {
    return AcquisitionUnits.getAcquisitionUnits(pagination).then((response) => {
      return response.data;
    });
  },
  getAcquisitionUnit(context, id) {
    return AcquisitionUnits.get({id}).then((response) => {
      return response.data;
    });
  },
  createAcquisitionUnit(context, item) {
    return AcquisitionUnits.create(item).then((response) => {
      return response.data;
    });
  },
  updateAcquisitionUnit(context, item) {
    return AcquisitionUnits.update(item).then((response) => {
      return response.data;
    });
  },
  destroyAcquisitionUnit(context, item) {
    return AcquisitionUnits.destroy(item).then((response) => {
      return response.data;
    });
  },
  getAcquisitionUnitField(context, item) {
    return AcquisitionUnits.getAcquisitionUnitField(item).then((response) => {
      return response.data;
    });
  },
};

const module = {
  state,
  mutations,
  actions
};

export default module;
