<template>
  <v-navigation-drawer :mini-variant="miniVariant"
                       app
                       class="blue-grey darken-4"
                       dark
                       fixed
                       persistent
                       v-if="authenticated"
                       v-model="drawer"
                       width="300">
    <v-toolbar class="transparent" flat>
      <v-list :class="{'list-border-bottom' : miniVariant}" class="pa-0">
        <v-list-tile>
          <v-list-tile-content v-if="!miniVariant">
            <div style="position: absolute; height: 100%;"
                 v-if="!miniVariant">
              <router-link :to="{ name: 'dashboard' }" class="white--text"
                           style="text-decoration: none;">
                <img src="../../assets/logo.png" class="center"
                     style="height: 100%;"/>
              </router-link>
            </div>
          </v-list-tile-content>
          <v-list-tile-action style="margin-right: -10px;">
            <v-btn @click.stop="miniVariant = !miniVariant" icon>
              <v-icon v-html="miniVariant ? 'chevron_right' : 'chevron_left'"></v-icon>
            </v-btn>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>
    </v-toolbar>

    <v-divider></v-divider>
    <v-list subheader>
      <v-subheader>Cadastros</v-subheader>
      <template v-for="(item, index) in List1">
        <v-tooltip :disabled="!miniVariant" right :key="index">
          <v-list-tile :key="item.icon"
                       :to="item.link ? {name: item.link, params: (item.params ? item.params : {})} : null"
                       exact
                       slot="activator">
            <v-list-tile-action>
              <v-icon v-html="item.icon"></v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title v-text="item.title"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <span v-text="item.title"></span>
        </v-tooltip>
      </template>
    </v-list>

    <v-divider></v-divider>
    <v-list subheader>
      <v-subheader>Sugerir Item</v-subheader>
      <template v-for="(item, index) in List2">
        <v-tooltip :disabled="!miniVariant" right :key="index">
          <v-list-tile
              :key="item.icon"
              :to="{name: item.link}"
              exact
              slot="activator"
          >
            <v-list-tile-action>
              <v-icon v-html="item.icon"></v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title v-text="item.title"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <span v-text="item.title"></span>
        </v-tooltip>
      </template>
    </v-list>

    <v-divider></v-divider>
    <v-list subheader>
      <v-subheader>Atas Pregões Eletrônicos</v-subheader>
      <template v-for="(item, index) in List3">
        <v-tooltip :disabled="!miniVariant" right :key="index">
          <v-list-tile :key="item.icon"
                       :to="{name: item.link}"
                       exact
                       slot="activator">
            <v-list-tile-action>
              <v-icon v-html="item.icon"></v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title v-text="item.title"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <span v-text="item.title"></span>
        </v-tooltip>
      </template>
    </v-list>

    <v-divider></v-divider>
    <v-list subheader>
      <v-subheader>Atas Registro de Preços</v-subheader>
      <template v-for="(item, index) in List4">
        <v-tooltip :disabled="!miniVariant" right :key="index">
          <v-list-tile :key="item.icon"
                       :to="{name: item.link}"
                       exact
                       slot="activator">
            <v-list-tile-action>
              <v-icon v-html="item.icon"></v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title v-text="item.title"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <span v-text="item.title"></span>
        </v-tooltip>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: [
    'authenticated',
    'user'
  ],
  data() {
    return {
      drawer: true,
      miniVariant: false,

      List1: [
        {
          icon: 'person_pin',
          title: 'Usuários Externo',
          link: 'external-users.edit',
          params: {id: this.user.external_user_id}
        },
        {
          icon: 'inventory',
          title: 'Itens',
          link: 'supply-products.index',
        }
      ],
      List2: [
        {
          icon: 'assistant_photo',
          title: 'Sugestão Item',
          link: 'suggested-items.index',
        },
      ],
      List3: [
        {
          icon: 'hardware',
          title: 'Atas Pregões Eletrônicos',
          link: 'electronic-auctions.index',
        },
      ],
      List4: [
        {
          icon: 'hardware',
          title: 'Atas Registro de Preços',
          link: 'price-records.index',
        },
      ],
    }
  },
  watch: {
    '$route': function (next, previous) {
      let variants = [];

      let variant = variants.includes(next.name);

      if (variants.includes(previous.name) && variants.includes(next.name)) {
        variant = true;
      }

      this.miniVariant = variant;
    }
  },
  mounted() {
  },
  methods: {},
}
</script>

<style scoped>
.v-navigation-drawer--mini-variant {
  overflow: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 5px;
  border-left: 1px solid hsla(0, 0%, 100%, .12);
}

::-webkit-scrollbar * {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #B0BEC5 !important;
  border-radius: 10px;
}
</style>
