import {AttributesItem} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
  getAttributesItem(context, pagination) {
    return AttributesItem.getAttributesItem(pagination).then((response) => {
      return response.data;
    });
  },
  getAttributeItem(context, id) {
    return AttributesItem.get({id}).then((response) => {
      return response.data;
    });
  },
  createAttributeItem(context, item) {
    return AttributesItem.create(item).then((response) => {
      return response.data;
    });
  },
  updateAttributeItem(context, item) {
    return AttributesItem.update(item).then((response) => {
      return response.data;
    });
  },
  destroyAttributeItem(context, item) {
    return AttributesItem.destroy(item).then((response) => {
      return response.data;
    });
  },
  getAttributeItemField(context, item) {
    return AttributesItem.getAttributeItemField(item).then((response) => {
      return response.data;
    });
  },
};

const module = {
  state,
  mutations,
  actions
};

export default module;
