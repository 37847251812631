import {Config} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getConfig(context, id) {
        return Config.get({id}).then((response) => {
            return response.data;
        });
    },
    updateConfig(context, item) {
        return Config.update(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
