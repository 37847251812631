import {Jwt} from "./resources";
import LocalStorageService from "./localStorage";
import 'es6-promise/auto'

const TOKEN = 'token';
const REFRESH_TOKEN = 'refresh_token';

export default {
  get token() {
    return LocalStorageService.get(TOKEN);
  },
  set token(value) {
    value ? LocalStorageService.set(TOKEN, value) : LocalStorageService.remove(TOKEN);
  },
  get refresh_token() {
    return LocalStorageService.get(REFRESH_TOKEN);
  },
  set refresh_token(value) {
    value ? LocalStorageService.set(REFRESH_TOKEN, value) : LocalStorageService.remove(REFRESH_TOKEN);
  },
  getAuthorizationHeader() {
    return `Bearer ${LocalStorageService.get(TOKEN)}`;
  },
  accessToken(email, password, mode = false) {
    return Jwt.accessToken(email, password, mode)
      .then((response) => {
        this.token = response.data.access_token;
        this._callEventUpdateToken(this.access_token);
        this.refresh_token = response.data.refresh_token;
        this._callEventUpdateToken(this.refresh_token);

        this.clearStoragePages();

        return response;
      });
  },
  refreshToken() {
    return Jwt.refreshToken(this.refresh_token).then((response) => {
      this.token = response.data.access_token;
      this._callEventUpdateToken(this.access_token);
      this.refresh_token = null;
      this._callEventUpdateToken(this.refresh_token);

      return response;
    });
  },
  revokeToken() {
    let afterReveokeToken = (response) => {
      this.token = null;
      this.refresh_token = null;

      this.clearStoragePages();

      return response;
    };

    return Jwt.logout().then(afterReveokeToken)
      .catch(afterReveokeToken);
  },
  _events: {
    'updateToken': []
  },
  event(name, callback) {
    this._events[name].push(callback);
  },
  _callEventUpdateToken(value) {
    for (let callback of this._events['updateToken']) {
      callback(value);
    }
  },
  clearStoragePages() {
    for (let item in localStorage) {
      if (item.match(/search_/) ||
        item.match(/headers_/)) {
        localStorage.removeItem(item);
      }
    }
  }
}
