import {PurchaseManagements} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
  getPurchaseManagements(context, pagination) {
    return PurchaseManagements.getPurchaseManagements(pagination).then((response) => {
      return response.data;
    });
  },
  getPurchaseManagement(context, id) {
    return PurchaseManagements.get({id}).then((response) => {
      return response.data;
    });
  },
  createPurchaseManagement(context, item) {
    return PurchaseManagements.create(item).then((response) => {
      return response.data;
    });
  },
  updatePurchaseManagement(context, item) {
    return PurchaseManagements.update(item).then((response) => {
      return response.data;
    });
  },
  destroyPurchaseManagement(context, item) {
    return PurchaseManagements.destroy(item).then((response) => {
      return response.data;
    });
  },
  getPurchaseManagementField(context, item) {
    return PurchaseManagements.getPurchaseManagementField(item).then((response) => {
      return response.data;
    });
  },
  getPurchaseManagementFieldPublic(context, item) {
    return PurchaseManagements.getPurchaseManagementFieldPublic(item).then((response) => {
      return response.data;
    });
  },
};

const module = {
  state,
  mutations,
  actions
};

export default module;
