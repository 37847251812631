import Vue from 'vue'
import Vuex from 'vuex'
import auth from "@/store/auth";
import user from "@/store/user";
import checks from "@/store/checks";
import dashboard from "@/store/dashboard";
import products from "@/store/products";
import attributes from "@/store/attributes-item";
import categories from "@/store/categories";
import subcategories from "@/store/subcategories";
import elements from "@/store/elements-item";
import tags from "@/store/tags";
import acquisition from "@/store/acquisition-units";
import usersPurchaseManagement from "@/store/users-purchase-management";
import purchaseManagements from "@/store/purchase-managements";
import config from "@/store/config";
import providers from "@/store/providers";
import externalUsers from "@/store/external_users";
import supplyProducts from "@/store/supply-products";
import supplyItemHeaders from "@/store/supply-product-headers";
import accessProfile from "@/store/access-profile"
import usersAccessProfile from "@/store/users-access-profile";
import requestItemSuggestions from "@/store/request-item-suggestions";
import suggestedItem from "@/store/suggested-items";
import itemsSuggestions from "@/store/items-suggestions";
import priceConsultations from "@/store/price-consultations";
import priceConsultationEstimates from "@/store/price-consultation-estimates";
import priceConsultationProviders from "@/store/price-consultation-providers";
import estimates from "@/store/estimates";
import electronicAuctions from "@/store/electronic-auctions";
import electronicAuctionItems from "@/store/electronic-auction-items";
import priceRecords from "@/store/price-records";
import priceRecordsItems from "@/store/price-records-items";
import costDefinitionRules from "@/store/cost-definition-rules";
import groupItems from "@/store/group-items";
import groupItemItems from "@/store/group-item-items";
import priceMap from "@/store/price-map";
import termReference from "@/store/term-reference";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        user,
        checks,
        dashboard,
        products,
        attributes,
        categories,
        subcategories,
        elements,
        tags,
        acquisition,
        usersPurchaseManagement,
        purchaseManagements,
        config,
        providers,
        externalUsers,
        supplyProducts,
        supplyItemHeaders,
        accessProfile,
        usersAccessProfile,
        requestItemSuggestions,
        suggestedItem,
        itemsSuggestions,
        priceConsultations,
        priceConsultationEstimates,
        priceConsultationProviders,
        estimates,
        electronicAuctions,
        electronicAuctionItems,
        priceRecords,
        priceRecordsItems,
        costDefinitionRules,
        groupItems,
        groupItemItems,
        priceMap,
        termReference
    }
});
