import {PriceConsultationEstimates} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
  getPriceConsultationEstimates(context, pagination) {
    return PriceConsultationEstimates.getPriceConsultationEstimates(pagination).then((response) => {
      return response.data;
    });
  },
  getPriceConsultationEstimate(context, id) {
    return PriceConsultationEstimates.get({id}).then((response) => {
      return response.data;
    });
  },
  destroyPriceConsultationEstimate(context, item) {
    return PriceConsultationEstimates.destroy(item).then((response) => {
      return response.data;
    });
  },
};

const module = {
  state,
  mutations,
  actions
};

export default module;
