import {UsersAccessProfiles} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getUsersAccessProfiles(context, pagination) {
        return UsersAccessProfiles.getUsersAccessProfiles(pagination).then((response) => {
            return response.data;
        });
    },
    getUsersAccessProfile(context, id) {
        return UsersAccessProfiles.get({id}).then((response) => {
            return response.data;
        });
    },
    createUsersAccessProfile(context, item) {
        return UsersAccessProfiles.create(item).then((response) => {
            return response.data;
        });
    },
    updateUsersAccessProfile(context, item) {
        return UsersAccessProfiles.update(item).then((response) => {
            return response.data;
        });
    },
    destroyUsersAccessProfile(context, item) {
        return UsersAccessProfiles.destroy(item).then((response) => {
            return response.data;
        });
    },
    sendUsersAccessProfileEmail(context, item) {
        return UsersAccessProfiles.sendUsersAccessProfileEmail(item).then((response) => {
            return response.data;
        });
    },
    addAccessProfile(context, item) {
        return UsersAccessProfiles.addAccessProfile(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
