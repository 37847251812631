import {TermReferences} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getTermReferences(context, pagination) {
        return TermReferences.getTermReferences(pagination).then((response) => {
            return response.data;
        });
    },
    getTermReference(context, id) {
        return TermReferences.get({id}).then((response) => {
            return response.data;
        });
    },
    createTermReference(context, item) {
        return TermReferences.create(item).then((response) => {
            return response.data;
        });
    },
    updateTermReference(context, item) {
        return TermReferences.update(item).then((response) => {
            return response.data;
        });
    },
    destroyTermReference(context, item) {
        return TermReferences.destroy(item).then((response) => {
            return response.data;
        });
    },
    getTermReferenceField(context, item) {
        return TermReferences.getTermReferenceField(item).then((response) => {
            return response.data;
        });
    },
    getTermReferenceDocuments(context, pagination) {
        return TermReferences.getTermReferenceDocuments(pagination).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};
export default module;
