import Vue from 'vue'
import VueResource from 'vue-resource'
import './interceptors'

Vue.use(VueResource);

export class Jwt {
    static dependenciesServiceAuth = {};

    static accessToken(email, password) {
        let link = 'api/token';

        return Vue.http.post(link, Object.assign(this.dependenciesServiceAuth, {
            'email': email,
            'password': password
        }));
    }

    static register(fields) {
        return Vue.http.post('api/oauth/register', fields);
    }

    static logout() {
        return Vue.http.post('api/logout');
    }

    static refreshToken(token) {
        /**
         * rever esta função
         */
        return Vue.http.post('api/token', Object.assign(this.dependenciesServiceAuth, {
            'grant_type': 'refresh_token',
            'refresh_token': token
        }));
    }

    static sendResetPassword(fields) {
        return Vue.http.post('api/password/email', fields);
    }

    static resetPassword(fields) {
        return Vue.http.post('api/password/reset', fields);
    }
}

let User = 'api/user';
User = Vue.resource({}, {}, {
    getUsers: {method: 'GET', url: `${User}/list`},
    getUser: {method: 'GET', url: User},
    setUserProfile: {method: 'POST', url: `${User}/edit`},
    getUserNotifications: {method: 'GET', url: `${User}/notifications`},
    getConfig: {method: 'POST', url: `${User}/config`},
    registerFinish: {method: 'POST', url: `${User}/finish`},
});

let Check = 'api/checks';
Check = Vue.resource({}, {}, {
    getAPPVersion: {method: 'GET', url: `${Check}/version`},
});

let Dashboard = 'api/dashboard';
Dashboard = Vue.resource({}, {}, {});

let Products = 'api/products';
Products = Vue.resource(`${Products}{/id}`, {}, {
    getProducts: {method: 'GET', url: `${Products}/list`},
    create: {method: 'POST', url: `${Products}/create`},
    update: {method: 'POST', url: `${Products}/update`},
    destroy: {method: 'POST', url: `${Products}/destroy`},
    getHistories: {method: 'POST', url: `${Products}/getHistories`},
    getProductField: {method: 'POST', url: `${Products}/getProductField`},
    changeProductSupply: {method: 'POST', url: `${Products}/changeProductSupply`},
});

let AttributesItem = 'api/attributes-item';
AttributesItem = Vue.resource(`${AttributesItem}{/id}`, {}, {
    getAttributesItem: {method: 'GET', url: `${AttributesItem}/list`},
    create: {method: 'POST', url: `${AttributesItem}/create`},
    update: {method: 'POST', url: `${AttributesItem}/update`},
    destroy: {method: 'POST', url: `${AttributesItem}/destroy`},
    getAttributeItemField: {method: 'POST', url: `${AttributesItem}/getAttributeItemField`},
});

let Category = 'api/categories';
Category = Vue.resource(`${Category}{/id}`, {}, {
    getCategories: {method: 'GET', url: `${Category}/list`},
    create: {method: 'POST', url: `${Category}/create`},
    update: {method: 'POST', url: `${Category}/update`},
    destroy: {method: 'POST', url: `${Category}/destroy`},
    getCategoryField: {method: 'POST', url: `${Category}/getCategoryField`},
});

let Subcategory = 'api/subcategories';
Subcategory = Vue.resource(`${Subcategory}{/id}`, {}, {
    getSubcategories: {method: 'GET', url: `${Subcategory}/list`},
    create: {method: 'POST', url: `${Subcategory}/create`},
    update: {method: 'POST', url: `${Subcategory}/update`},
    destroy: {method: 'POST', url: `${Subcategory}/destroy`},
    getSubcategoryField: {method: 'POST', url: `${Subcategory}/getSubcategoryField`},
});

let ElementsItem = 'api/elements-item';
ElementsItem = Vue.resource(`${ElementsItem}{/id}`, {}, {
    getElementsItem: {method: 'GET', url: `${ElementsItem}/list`},
    create: {method: 'POST', url: `${ElementsItem}/create`},
    update: {method: 'POST', url: `${ElementsItem}/update`},
    destroy: {method: 'POST', url: `${ElementsItem}/destroy`},
    getElementItemField: {method: 'POST', url: `${ElementsItem}/getElementItemField`},
});

let Tags = 'api/tags';
Tags = Vue.resource(`${Tags}{/id}`, {}, {
    getTags: {method: 'GET', url: `${Tags}/list`},
    create: {method: 'POST', url: `${Tags}/create`},
    update: {method: 'POST', url: `${Tags}/update`},
    destroy: {method: 'POST', url: `${Tags}/destroy`},
    getTagField: {method: 'POST', url: `${Tags}/getTagField`},
});

let AcquisitionUnits = 'api/acquisition-units';
AcquisitionUnits = Vue.resource(`${AcquisitionUnits}{/id}`, {}, {
    getAcquisitionUnits: {method: 'GET', url: `${AcquisitionUnits}/list`},
    create: {method: 'POST', url: `${AcquisitionUnits}/create`},
    update: {method: 'POST', url: `${AcquisitionUnits}/update`},
    destroy: {method: 'POST', url: `${AcquisitionUnits}/destroy`},
    getAcquisitionUnitField: {method: 'POST', url: `${AcquisitionUnits}/getAcquisitionUnitField`},
});

let UserPurchaseManagements = 'api/users-purchase-management';
UserPurchaseManagements = Vue.resource(`${UserPurchaseManagements}{/id}`, {}, {
    getUserPurchaseManagements: {method: 'GET', url: `${UserPurchaseManagements}/list`},
    create: {method: 'POST', url: `${UserPurchaseManagements}/create`},
    update: {method: 'POST', url: `${UserPurchaseManagements}/update`},
    destroy: {method: 'POST', url: `${UserPurchaseManagements}/destroy`},
    getUserPurchaseManagementField: {method: 'POST', url: `${UserPurchaseManagements}/getUserPurchaseManagementField`},
    importUserPurchaseManagement: {method: 'POST', url: `${UserPurchaseManagements}/importUserPurchaseManagement`},
});

let PurchaseManagements = 'api/purchase-managements';
PurchaseManagements = Vue.resource(`${PurchaseManagements}{/id}`, {}, {
    getPurchaseManagements: {method: 'GET', url: `${PurchaseManagements}/list`},
    create: {method: 'POST', url: `${PurchaseManagements}/create`},
    update: {method: 'POST', url: `${PurchaseManagements}/update`},
    destroy: {method: 'POST', url: `${PurchaseManagements}/destroy`},
    getPurchaseManagementField: {method: 'POST', url: `${PurchaseManagements}/getPurchaseManagementField`},
    getPurchaseManagementFieldPublic: {method: 'POST', url: `${PurchaseManagements}/getPurchaseManagementField/public`},
});

let Config = 'api/config';
Config = Vue.resource(`${Config}`, {}, {
    getConfig: {method: 'GET', url: `${Config}/show`}, update: {method: 'POST', url: `${Config}/update`},
});

let Providers = 'api/providers';
Providers = Vue.resource(`${Providers}{/id}`, {}, {
    getProviders: {method: 'GET', url: `${Providers}/list`},
    create: {method: 'POST', url: `${Providers}/create`},
    update: {method: 'POST', url: `${Providers}/update`},
    createPublic: {method: 'POST', url: `${Providers}/create/public`},
    updatePublic: {method: 'POST', url: `${Providers}/update/public`},
    destroy: {method: 'POST', url: `${Providers}/destroy`},
    getProviderField: {method: 'POST', url: `${Providers}/getProviderField`},
    sendProvidersEmail: {method: 'POST', url: `${Providers}/sendProvidersEmail`},
    approveProviders: {method: 'POST', url: `${Providers}/approveProviders`},
});

let ExternalUsers = 'api/external-users';
ExternalUsers = Vue.resource(`${ExternalUsers}{/id}`, {}, {
    getExternalUsers: {method: 'GET', url: `${ExternalUsers}/list`},
    create: {method: 'POST', url: `${ExternalUsers}/create`},
    update: {method: 'POST', url: `${ExternalUsers}/update`},
    createPublic: {method: 'POST', url: `${ExternalUsers}/create/public`},
    updatePublic: {method: 'POST', url: `${ExternalUsers}/update/public`},
    destroy: {method: 'POST', url: `${ExternalUsers}/destroy`},
    getExternalUserField: {method: 'POST', url: `${ExternalUsers}/getExternalUserField`},
    sendExternalUsersEmail: {method: 'POST', url: `${ExternalUsers}/sendExternalUsersEmail`},
    approveExternalUsers: {method: 'POST', url: `${ExternalUsers}/approveExternalUsers`},
    requestAccessRenewalExternalUsers: {method: 'POST', url: `${ExternalUsers}/requestAccessRenewalExternalUsers`},
});

let SupplyProducts = 'api/supply-items';
SupplyProducts = Vue.resource(`${SupplyProducts}{/id}`, {}, {
    getSupplyProducts: {method: 'GET', url: `${SupplyProducts}/list`},
    create: {method: 'POST', url: `${SupplyProducts}/create`},
    update: {method: 'POST', url: `${SupplyProducts}/update`},
    destroy: {method: 'POST', url: `${SupplyProducts}/destroy`},
    getSupplyProductList: {method: 'POST', url: `${SupplyProducts}/getSupplyProductList`},
    createHeaderSupplyProduct: {method: 'POST', url: `${SupplyProducts}/createHeaderSupplyProduct`},
    cancelSupplyProduct: {method: 'POST', url: `${SupplyProducts}/cancelSupplyProduct`},
    addHeaderSupplyProduct: {method: 'POST', url: `${SupplyProducts}/addHeaderSupplyProduct`},
    getSupplyItemsHeaders: {method: 'GET', url: `${SupplyProducts}/getSupplyItemsHeaders`},
    sendSupplyItemsEmail: {method: 'POST', url: `${SupplyProducts}/sendSupplyItemsEmail`},
});

let SupplyProductHeaders = 'api/supply-item-headers';
SupplyProductHeaders = Vue.resource(`${SupplyProductHeaders}{/id}`, {}, {
    getSupplyProductHeaders: {method: 'GET', url: `${SupplyProductHeaders}/list`},
});

let AccessProfiles = 'api/access-profile';
AccessProfiles = Vue.resource(`${AccessProfiles}{/id}`, {}, {
    getAccessProfiles: {method: 'GET', url: `${AccessProfiles}/list`},
    create: {method: 'POST', url: `${AccessProfiles}/create`},
    update: {method: 'POST', url: `${AccessProfiles}/update`},
    destroy: {method: 'POST', url: `${AccessProfiles}/destroy`},
    getRolesModules: {method: 'GET', url: `${AccessProfiles}/getRolesModules`},
    syncPermissions: {method: 'POST', url: `${AccessProfiles}/syncPermissions`},
    getRequestAccessProfile: {method: 'GET', url: `${AccessProfiles}/getRequestAccessProfile`},
    createRequestAccessProfile: {method: 'POST', url: `${AccessProfiles}/createRequestAccessProfile`},
    destroyRequestAccessProfile: {method: 'POST', url: `${AccessProfiles}/destroyRequestAccessProfile`},
});

let UsersAccessProfiles = 'api/users-access-profile';
UsersAccessProfiles = Vue.resource(`${UsersAccessProfiles}{/id}`, {}, {
    getUsersAccessProfiles: {method: 'GET', url: `${UsersAccessProfiles}/list`},
    create: {method: 'POST', url: `${UsersAccessProfiles}/create`},
    update: {method: 'POST', url: `${UsersAccessProfiles}/update`},
    destroy: {method: 'POST', url: `${UsersAccessProfiles}/destroy`},
    sendUsersAccessProfileEmail: {method: 'POST', url: `${UsersAccessProfiles}/sendUsersAccessProfileEmail`},
    addAccessProfile: {method: 'POST', url: `${UsersAccessProfiles}/addAccessProfile`},
});

let RequestItemSuggestions = 'api/request-item-suggestions';
RequestItemSuggestions = Vue.resource(`${RequestItemSuggestions}{/id}`, {}, {
    getRequestItemSuggestions: {method: 'GET', url: `${RequestItemSuggestions}/list`},
    create: {method: 'POST', url: `${RequestItemSuggestions}/create`},
    update: {method: 'POST', url: `${RequestItemSuggestions}/update`},
    destroy: {method: 'POST', url: `${RequestItemSuggestions}/destroy`},
    getRequestItemSuggestionField: {method: 'POST', url: `${RequestItemSuggestions}/getRequestItemSuggestionField`},
    sendRequestItemSuggestionsEmail: {method: 'POST', url: `${RequestItemSuggestions}/sendRequestItemSuggestionsEmail`},
});

let SuggestedItems = 'api/suggested-items';
SuggestedItems = Vue.resource(`${SuggestedItems}{/id}`, {}, {
    getSuggestedItems: {method: 'GET', url: `${SuggestedItems}/list`},
    create: {method: 'POST', url: `${SuggestedItems}/create`},
    update: {method: 'POST', url: `${SuggestedItems}/update`},
    destroy: {method: 'POST', url: `${SuggestedItems}/destroy`},
});

let ItemSuggestions = 'api/items-suggestions';
ItemSuggestions = Vue.resource(`${ItemSuggestions}{/id}`, {}, {
    getItemSuggestions: {method: 'GET', url: `${ItemSuggestions}/list`},
    create: {method: 'POST', url: `${ItemSuggestions}/create`},
    update: {method: 'POST', url: `${ItemSuggestions}/update`},
    destroy: {method: 'POST', url: `${ItemSuggestions}/destroy`},
    changeItemSuggestionTemp: {method: 'POST', url: `${ItemSuggestions}/changeItemSuggestionTemp`},
    sendItemSuggestionsEmail: {method: 'POST', url: `${ItemSuggestions}/sendItemSuggestionsEmail`},
});

let PriceConsultations = 'api/price-consultations';
PriceConsultations = Vue.resource(`${PriceConsultations}{/id}`, {}, {
    getPriceConsultations: {method: 'GET', url: `${PriceConsultations}/list`},
    create: {method: 'POST', url: `${PriceConsultations}/create`},
    update: {method: 'POST', url: `${PriceConsultations}/update`},
    destroy: {method: 'POST', url: `${PriceConsultations}/destroy`},
    getPriceConsultationField: {method: 'POST', url: `${PriceConsultations}/getPriceConsultationField`},
    getPriceConsultationItemsField: {method: 'POST', url: `${PriceConsultations}/getPriceConsultationItemsField`},
});

let PriceConsultationEstimates = 'api/price-consultation-estimates';
PriceConsultationEstimates = Vue.resource(`${PriceConsultationEstimates}{/id}`, {}, {
    getPriceConsultationEstimates: {method: 'GET', url: `${PriceConsultationEstimates}/list`},
    destroy: {method: 'POST', url: `${PriceConsultationEstimates}/destroy`},
});

let PriceConsultationProviders = 'api/price-consultation-providers';
PriceConsultationProviders = Vue.resource({}, {}, {
    getPriceConsultationProviders: {method: 'GET', url: `${PriceConsultationProviders}/list`},
    sendPriceConsultationProvidersEmail: {
        method: 'POST',
        url: `${PriceConsultationProviders}/sendPriceConsultationProvidersEmail`
    },
});

let Estimates = 'api/estimates';
Estimates = Vue.resource(`${Estimates}{/id}`, {}, {
    getEstimates: {method: 'GET', url: `${Estimates}/list`},
    create: {method: 'POST', url: `${Estimates}/create`},
    update: {method: 'POST', url: `${Estimates}/update`},
    destroy: {method: 'POST', url: `${Estimates}/destroy`},
    getEstimateField: {method: 'POST', url: `${Estimates}/getEstimateField`},
    statusEstimates: {method: 'POST', url: `${Estimates}/statusEstimates`},
    statusEstimateItems: {method: 'POST', url: `${Estimates}/statusEstimateItems`},
    sendEstimatesEmail: {method: 'POST', url: `${Estimates}/sendEstimatesEmail`},
});

let ElectronicAuctions = 'api/electronic-auctions';
ElectronicAuctions = Vue.resource(`${ElectronicAuctions}{/id}`, {}, {
    getElectronicAuctions: {method: 'GET', url: `${ElectronicAuctions}/list`},
    create: {method: 'POST', url: `${ElectronicAuctions}/create`},
    update: {method: 'POST', url: `${ElectronicAuctions}/update`},
    destroy: {method: 'POST', url: `${ElectronicAuctions}/destroy`},
    getElectronicAuctionField: {method: 'POST', url: `${ElectronicAuctions}/getElectronicAuctionField`},
    sendElectronicAuctionsEmail: {method: 'POST', url: `${ElectronicAuctions}/sendElectronicAuctionsEmail`},
    checkIdentifierElectronicAuctions: {method: 'POST', url: `${ElectronicAuctions}/checkIdentifierElectronicAuctions`},
});

let ElectronicAuctionItems = 'api/electronic-auction-items';
ElectronicAuctionItems = Vue.resource(`${ElectronicAuctionItems}{/id}`, {}, {
    getElectronicAuctionItems: {method: 'GET', url: `${ElectronicAuctionItems}/list`},
});

let PriceRecords = 'api/price-records';
PriceRecords = Vue.resource(`${PriceRecords}{/id}`, {}, {
    getPriceRecords: {method: 'GET', url: `${PriceRecords}/list`},
    create: {method: 'POST', url: `${PriceRecords}/create`},
    update: {method: 'POST', url: `${PriceRecords}/update`},
    destroy: {method: 'POST', url: `${PriceRecords}/destroy`},
    getPriceRecordField: {method: 'POST', url: `${PriceRecords}/getPriceRecordField`},
    sendPriceRecordsEmail: {method: 'POST', url: `${PriceRecords}/sendPriceRecordsEmail`},
    checkIdentifierPriceRecords: {method: 'POST', url: `${PriceRecords}/checkIdentifierPriceRecords`},
});

let PriceRecordItems = 'api/price-record-items';
PriceRecordItems = Vue.resource(`${PriceRecordItems}{/id}`, {}, {
    getPriceRecordItems: {method: 'GET', url: `${PriceRecordItems}/list`},
});

let CostDefinitionRules = 'api/cost-definition-rules';
CostDefinitionRules = Vue.resource(`${CostDefinitionRules}{/id}`, {}, {
    getCostDefinitionRules: {method: 'GET', url: `${CostDefinitionRules}/list`},
    create: {method: 'POST', url: `${CostDefinitionRules}/create`},
    update: {method: 'POST', url: `${CostDefinitionRules}/update`},
    destroy: {method: 'POST', url: `${CostDefinitionRules}/destroy`},
    getCostDefinitionRuleField: {method: 'POST', url: `${CostDefinitionRules}/getCostDefinitionRuleField`},
    updateCostDefinitionRuleProduct: {method: 'POST', url: `${CostDefinitionRules}/updateCostDefinitionRuleProduct`},
    calculateAverageCostRule: {
        method: 'POST',
        url: `${CostDefinitionRules}/calculateAverageCostRule/{product_id}/{average_cost_rule_id}`
    },
    calculateLowestCostRule: {
        method: 'POST',
        url: `${CostDefinitionRules}/calculateLowestCostRule/{product_id}/{lowest_cost_rule_id}`
    },
});

let GroupItems = 'api/group-items';
GroupItems = Vue.resource(`${GroupItems}{/id}`, {}, {
    getGroupItems: {method: 'GET', url: `${GroupItems}/list`},
    create: {method: 'POST', url: `${GroupItems}/create`},
    update: {method: 'POST', url: `${GroupItems}/update`},
    destroy: {method: 'POST', url: `${GroupItems}/destroy`},
    getGroupItemField: {method: 'POST', url: `${GroupItems}/getGroupItemField`},
});
let GroupItemItems = 'api/group-item-items';
GroupItemItems = Vue.resource(`${GroupItemItems}{/id}`, {}, {
    getGroupItemItems: {method: 'GET', url: `${GroupItemItems}/list`},
});

let PriceMaps = 'api/price-map';
PriceMaps = Vue.resource(`${PriceMaps}{/id}`, {}, {
    getPriceMaps: {method: 'GET', url: `${PriceMaps}/list`},
    create: {method: 'POST', url: `${PriceMaps}/create`},
    update: {method: 'POST', url: `${PriceMaps}/update`},
    destroy: {method: 'POST', url: `${PriceMaps}/destroy`},
    getPriceMapField: {method: 'POST', url: `${PriceMaps}/getPriceMapField`},
    getPriceMapDocuments: {method: 'GET', url: `${PriceMaps}/getPriceMapDocuments`},
});

let TermReferences = 'api/term-reference';
TermReferences = Vue.resource(`${TermReferences}{/id}`, {}, {
    getTermReferences: {method: 'GET', url: `${TermReferences}/list`},
    create: {method: 'POST', url: `${TermReferences}/create`},
    update: {method: 'POST', url: `${TermReferences}/update`},
    destroy: {method: 'POST', url: `${TermReferences}/destroy`},
    getTermReferenceField: {method: 'POST', url: `${TermReferences}/getTermReferenceField`},
});

export {
    User,
    Check,
    Dashboard,
    Products,
    AttributesItem,
    Category,
    Subcategory,
    ElementsItem,
    Tags,
    AcquisitionUnits,
    UserPurchaseManagements,
    PurchaseManagements,
    Config,
    Providers,
    ExternalUsers,
    SupplyProducts,
    AccessProfiles,
    UsersAccessProfiles,
    RequestItemSuggestions,
    SuggestedItems,
    ItemSuggestions,
    PriceConsultations,
    PriceConsultationProviders,
    PriceConsultationEstimates,
    Estimates,
    ElectronicAuctions,
    ElectronicAuctionItems,
    PriceRecords,
    PriceRecordItems,
    CostDefinitionRules,
    GroupItems,
    GroupItemItems,
    PriceMaps,
    TermReferences,
    SupplyProductHeaders
};
