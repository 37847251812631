import {SuggestedItems} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
  getSuggestedItems(context, pagination) {
    return SuggestedItems.getSuggestedItems(pagination).then((response) => {
      return response.data;
    });
  },
  getSuggestedItem(context, id) {
    return SuggestedItems.get({id}).then((response) => {
      return response.data;
    });
  },
  createSuggestedItem(context, item) {
    return SuggestedItems.create(item).then((response) => {
      return response.data;
    });
  },
  updateSuggestedItem(context, item) {
    return SuggestedItems.update(item).then((response) => {
      return response.data;
    });
  },
  destroySuggestedItem(context, item) {
    return SuggestedItems.destroy(item).then((response) => {
      return response.data;
    });
  },
  getSuggestedItemField(context, item) {
    return SuggestedItems.getSuggestedItemField(item).then((response) => {
      return response.data;
    });
  },
};

const module = {
  state,
  mutations,
  actions
};

export default module;
