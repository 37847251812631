import {SupplyProducts} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getSupplyProducts(context, pagination) {
        return SupplyProducts.getSupplyProducts(pagination).then((response) => {
            return response.data;
        });
    },
    getSupplyProduct(context, id) {
        return SupplyProducts.get({id}).then((response) => {
            return response.data;
        });
    },
    createSupplyProduct(context, product) {
        return SupplyProducts.create(product).then((response) => {
            return response.data;
        });
    },
    updateSupplyProduct(context, product) {
        return SupplyProducts.update(product).then((response) => {
            return response.data;
        });
    },
    destroySupplyProduct(context, product) {
        return SupplyProducts.destroy(product).then((response) => {
            return response.data;
        });
    },
    getSupplyProductList(context, product) {
        return SupplyProducts.getSupplyProductList(product).then((response) => {
            return response.data;
        });
    },
    createHeaderSupplyProduct(context, product) {
        return SupplyProducts.createHeaderSupplyProduct(product).then((response) => {
            return response.data;
        });
    },
    cancelSupplyProduct(context, product) {
        return SupplyProducts.cancelSupplyProduct(product).then((response) => {
            return response.data;
        });
    },
    addHeaderSupplyProduct(context, product) {
        return SupplyProducts.addHeaderSupplyProduct(product).then((response) => {
            return response.data;
        });
    },
    getSupplyItemsHeaders(context, pagination) {
        return SupplyProducts.getSupplyItemsHeaders(pagination).then((response) => {
            return response.data;
        });
    },
    sendSupplyItemsEmail(context, item) {
        return SupplyProducts.sendSupplyItemsEmail(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
