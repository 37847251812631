import {PriceRecordItems} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getPriceRecordItems(context, pagination) {
        return PriceRecordItems.getPriceRecordItems(pagination).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
