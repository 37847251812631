import {User} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getUsers(context, pagination) {
        return User.getUsers(pagination).then((response) => {
            return response.data;
        });
    },
    getUser(context) {
        return User.getUser().then((response) => {
            context.commit('setUser', response.data);

            return response;
        });
    },
    setUserProfile(context, data) {
        return User.setUserProfile(data).then((response) => {
            return response;
        });
    },
    getUserNotifications() {
        return User.getUserNotifications().then((response) => {
            return response;
        });
    },
    // getConfig(context, data) {
    //     return User.getConfig(data).then((response) => {
    //         return response;
    //     });
    // },
    registerFinish(context, data) {
        return User.registerFinish(data).then((response) => {
            return response;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
